<template>
  <div class="language-selector">
    <select v-model="currentLanguage" @change="changeLanguage">
      <option v-for="lang in availableLanguages" :key="lang.code" :value="lang.code">
        {{ lang.flag }} {{ lang.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

const { locale } = useI18n();
const router = useRouter();
const route = useRoute();

const availableLanguages = [
  { code: 'en', name: 'English', flag: '🇺🇸' },
  { code: 'ja', name: '日本語', flag: '🇯🇵' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
];

const currentLanguage = computed({
  get: () => locale.value,
  set: (newLang) => { locale.value = newLang }
});

const changeLanguage = () => {
  const newPath = `/${currentLanguage.value}${route.fullPath.replace(/^\/[^/]+/, '')}`;
  router.push(newPath);
};
</script>

<style scoped>
.language-selector {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: inline-block;
}

.language-selector select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  color: #396b54;
  font-size: 14px;
  padding: 8px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-selector select:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* IE用のデフォルト矢印を非表示にする */
.language-selector select::-ms-expand {
  display: none;
}

@media (max-width: 768px) {
  .language-selector {
    bottom: 10px;
    right: 10px;
  }

  .language-selector select {
    font-size: 12px;
    padding: 6px 12px;
  }
}
</style>