<template>
    <div class="container my-5" @click="closeMenu">
        <div class="header">
            <img src="https://i.imgur.com/T2eCD9R.png" alt="Logo" class="logo">
            <h1>{{ rt(tm('optimizeClose.title')) }}</h1>
            <div class="user-info">
                <i class="fas fa-bars fa-lg" @click.stop="toggleMenu"></i>
                <div v-if="isMenuVisible" class="modern-menu" :class="{ 'menu-width-50': isStripeVisible }">
                    <ul>
                        <li class="user-email" style="color: brown; font-size: 12px;">
                            <i class="far fa-envelope"></i> {{ userEmail }}
                        </li>
                        <li v-if="planStatus === 'trialing'" class="trialing" style="color: orange;font-size: 13px;">
                            <i class="fas fa-award"></i> {{ rt(tm('optimizeClose.trialStatus')) }}
                        </li>
                        <li v-if="planStatus === 'active' || planStatus === 'past_due'" class="plusPlan"
                            style="color: orange;font-size: 13px;">
                            <i class="fas fa-award"></i> {{ rt(tm('optimizeClose.plusPlan')) }}
                        </li>
                        <li v-if="planStatus === null || planStatus === 'canceled' || planStatus === 'incomplete' || planStatus === 'incomplete_expired' || planStatus === 'unpaid' || planStatus === 'paused' || planStatus === 'NoSubscriptions'"
                            class="user-email" style="color: black; font-size: 12px;">
                            {{ rt(tm('optimizeClose.calculationCount', { count: planningCountField })) }} :
                            {{ planningCountField }} / 20
                        </li>
                        <li v-if="planStatus === null || planStatus === 'canceled' || planStatus === 'incomplete' || planStatus === 'incomplete_expired' || planStatus === 'unpaid' || planStatus === 'paused' || planStatus === 'NoSubscriptions'"
                            @click.stop="toggleStripe" style="color: orange;font-size: 13px;">
                            {{ rt(tm('optimizeClose.aboutPlusPlan')) }}
                        </li>
                        <div v-if="isStripeVisible && !clientSecret" class="loading-container">
                            <div class="loading-text">
                                <i class="fas fa-sync-alt fa-spin"></i>
                                {{ rt(tm('optimizeClose.loading')) }}
                            </div>
                            <div class="bar-container">
                                <div class="bar"></div>
                            </div>
                        </div>
                        <div v-if="isStripeVisible && clientSecret">
                            <component :is="'stripe-pricing-table'" pricing-table-id="prctbl_1PR3VdAnOJojEh5QAgX60dOL"
                                publishable-key="pk_live_51JBrGwAnOJojEh5Qsi4ewWeckc1DWT6aOqWMM79xZ7MLOxhCUCibdCmyMsuKqgXTxbYnqNtHSUGp68Yu1LdrfSxk00DHiWPwxA"
                                :customer-session-client-secret="clientSecret">
                            </component>
                        </div>
                        <li style="font-size: 13px;" v-if="planStatus" @click="redirectToBillingPortal">
                            <i v-if="planStatus === 'incomplete' || planStatus === 'past_due' || planStatus === 'unpaid'"
                                class="fas fa-exclamation-circle" style="color: #ff0000;"></i>
                            {{ rt(tm('optimizeClose.contractInfo')) }}
                        </li>
                        <li style="font-size: 13px;">
                            <router-link :to="`/${$route.params.lang || 'en'}/change-email`" class="menu-link">{{ rt(tm('optimizeClose.changeEmail')) }}</router-link>
                        </li>
                        <li style="font-size: 13px;">
                            <a href="https://manufactureoptimizer.notion.site/Manufacture-Optimizer-f6e9229c67aa4c89a2cd7a15031fc42c"
                                target="_blank" class="menu-link">{{ rt(tm('optimizeClose.userManual')) }}</a>
                        </li>
                        <li style="font-size: 13px;">
                            <a href="https://forms.gle/zixyy63X2bBAoof77" target="_blank" class="menu-link">{{
                                rt(tm('optimizeClose.contact')) }}</a>
                        </li>
                        <li @click="logout" style="font-size: 13px;">{{ rt(tm('optimizeClose.logout')) }}</li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-if="redirecting" class="redirect-message">
            {{ rt(tm(redirectMessage)) }}
        </div>

        <!-- 制約条件選択ボタンと選択された制約条件表示 -->
        <div class="algorithm-selection-container">
            <button @click="showAlgorithmSelection" class="btn btn-secondary">{{
                rt(tm('optimizeClose.selectConstraints')) }}</button>
            <span v-if="selectedAlgorithm" class="selected-algorithm">
                {{ selectedAlgorithm.name }}
            </span>
        </div>

        <!-- 制約条件選択ポップアップ -->
        <div v-if="isAlgorithmSelectionVisible" class="popup-overlay">
            <div class="popup-content">
                <h2>{{ rt(tm('optimizeClose.constraints')) }}</h2>
                <div class="table-responsive">
                    <table class="algorithm-table">
                        <thead>
                            <tr>
                                <th>{{ rt(tm('optimizeClose.name')) }}</th>
                                <th>{{ rt(tm('optimizeClose.description')) }}</th>
                                <th>{{ rt(tm('optimizeClose.select')) }}</th>
                                <th class="favorite-column"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="algorithm in sortedAlgorithms" :key="algorithm.id">
                                <td class="name-column">
                                    {{ algorithm.name }}
                                    <i v-if="algorithm.PremiumOnly" class="fas fa-crown premium-icon"
                                        :title="rt(tm('optimizeClose.plusPlanOnly'))"></i>
                                </td>
                                <td class="description-column" v-html="formatDescription(algorithm.description)"></td>
                                <td class="select-column">
                                    <button @click="selectAlgorithm(algorithm)" class="btn btn-primary btn-sm"
                                        :disabled="!canSelectPremiumAlgorithm && algorithm.PremiumOnly">
                                        {{ rt(tm('optimizeClose.select')) }}
                                    </button>
                                </td>
                                <td class="favorite-column">
                                    <button @click="toggleFavorite(algorithm)" class="btn btn-link favorite-btn">
                                        <i class="fas fa-star" :class="{ 'favorite': algorithm.isFavorite }"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button @click="closeAlgorithmSelection" class="btn btn-secondary mt-3">{{ rt(tm('optimizeClose.close'))
                    }}</button>
            </div>
        </div>

        <form @submit.prevent="optimize">
            <DataInput :title="rt(tm('optimizeClose.demandData'))" id="demandFile" v-model="demandData"
                @change="event => handleFileChange(event, 'demand')" />
            <p v-if="demandError" class="text-danger">{{ demandError }}</p>
            <DataInput :title="rt(tm('optimizeClose.productMaster'))" id="productMasterFile" v-model="productMasterData"
                @change="event => handleFileChange(event, 'productMaster')" />
            <p v-if="productMasterError" class="text-danger">{{ productMasterError }}</p>
            <DataInput :title="rt(tm('optimizeClose.resourceMaster'))" id="machineResourcesFile"
                v-model="machineResourcesData" @change="event => handleFileChange(event, 'machineResources')" />
            <p v-if="machineResourcesError" class="text-danger">{{ machineResourcesError }}</p>
            <div class="text-center mt-4">
                <button type="submit" class="btn btn-primary btn-lg" :disabled="isFormInvalid || isCalculating">
                    {{ rt(tm(isCalculating ? 'optimizeClose.calculating' : 'optimizeClose.optimize')) }}
                </button>
            </div>
        </form>
        <div v-if="isCalculating" class="text-center mt-3">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">{{ rt(tm('optimizeClose.calculating')) }}</span>
            </div>
            <p class="mt-2">{{ rt(tm('optimizeClose.calculatingMessage')) }}</p>
        </div>
        <ResultDisplay :result="optimizationResult" :demandData="demandData"
            :machineResourcesData="machineResourcesData" />
    </div>
</template>

<script setup>
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted, watch , computed} from 'vue';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, query, orderBy, limit, setDoc, Timestamp } from 'firebase/firestore';
import { db } from '@/firebase/index';
import DataInput from './componentsDetail/DataInput.vue';
import ResultDisplay from './componentsDetail/ResultDisplay.vue';
import { getAnalytics, logEvent } from "firebase/analytics";
import { useI18n } from 'vue-i18n';

const { tm, rt } = useI18n();

const route = useRoute();

// 現在の言語を取得し、設定されていない場合は 'en' をデフォルトとして使用
const currentLang = computed(() => route.params.lang || 'en');

const demandData = ref('');
const productMasterData = ref('');
const machineResourcesData = ref('');
const optimizationResult = ref(null);
const isCalculating = ref(false);

const formatDescription = (description) => {
    return description.replace(/\n/g, '<br>');
};

const demandError = ref('');
const productMasterError = ref('');
const machineResourcesError = ref('');

const isFormInvalid = computed(() => {
    return demandError.value !== '' || productMasterError.value !== '' || machineResourcesError.value !== '' || !demandData.value || !productMasterData.value || !machineResourcesData.value;
});

const validateFile = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;

            const lines = content.split('\n');
            for (const line of lines) {
                if (!(line.includes('\t') || line.trim() === '')) {
                    console.log(line)
                    resolve(rt(tm('optimizeClose.selectTsvFile')));
                    return;
                }
            }
            resolve('');
        };
        reader.readAsText(file);
    });
};

const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    validateFile(file).then((errorMessage) => {
        if (type === 'demand') demandError.value = errorMessage;
        else if (type === 'productMaster') productMasterError.value = errorMessage;
        else if (type === 'machineResources') machineResourcesError.value = errorMessage;

        if (!errorMessage) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (type === 'demand') demandData.value = e.target.result;
                else if (type === 'productMaster') productMasterData.value = e.target.result;
                else if (type === 'machineResources') machineResourcesData.value = e.target.result;
            };
            reader.readAsText(file);
        }
    });
};


const userEmail = ref('');
const isMenuVisible = ref(false);
const isStripeVisible = ref(false);
const clientSecret = ref('');
const planStatus = ref(null);
const planningCountField = ref(0);
const router = useRouter();
const firebaseAuth = getAuth();
const currentUser = ref(firebaseAuth.currentUser);
const stripeId = ref('');

const toggleMenu = () => {
    isMenuVisible.value = !isMenuVisible.value;
};

const closeMenu = () => {
    isMenuVisible.value = false;
};

const logout = async () => {
    try {
        await signOut(firebaseAuth);
        router.push(`/${currentLang.value}/login`);
    } catch (error) {
        console.error('Logout failed:', error);
    }
};

const toggleStripe = async () => {
    isStripeVisible.value = !isStripeVisible.value;

    if (isStripeVisible.value && !clientSecret.value) {
        try {
            const uid = currentUser.value.uid;
            const customerDocRef = doc(db, 'customers', uid);
            const customerDoc = await getDoc(customerDocRef);

            if (customerDoc.exists()) {
                const stripeId = customerDoc.get('stripeId');

                try {
                    const response = await axios.post('https://us-central1-aligngengpt.cloudfunctions.net/https_flask_app/create-customer-session', {
                        //      const response = await axios.post('http://localhost:5001/create-customer-session', {
                        stripeId: stripeId,
                    });
                    clientSecret.value = response.data.client_secret;
                    startClientSecretTimer();
                } catch (error) {
                    console.error('Error creating customer session:', error);
                }
            } else {
                console.log(`No customer document found for UID ${uid}.`);
            }
        } catch (error) {
            console.error('Error fetching data from Firestore:', error);
        }
    }
};

const startClientSecretTimer = () => {
    setTimeout(() => {
        clientSecret.value = '';
    }, 1800000); // 30 minutes in milliseconds
};

const redirecting = ref(false);
const redirectMessage = ref('');

const redirectToBillingPortal = async () => {
    redirecting.value = true;
    redirectMessage.value = 'optimizeClose.redirectingToBillingPortal';

    try {
        const uid = currentUser.value.uid;
        const customerDocRef = doc(db, 'customers', uid);
        const customerDoc = await getDoc(customerDocRef);

        if (customerDoc.exists()) {
            const stripeId = customerDoc.get('stripeId');

            try {
                const response = await axios.post('https://us-central1-aligngengpt.cloudfunctions.net/https_flask_app/create-billing-portal-session', {
                    //       const response = await axios.post('http://localhost:5001/create-billing-portal-session', {
                    stripeId: stripeId
                });

                const { url } = response.data;
                window.location.href = url;
            } catch (error) {
                console.error('Error redirecting to billing portal:', error);
                redirectMessage.value = 'optimizeClose.errorTryAgain';
            }
        } else {
            redirectMessage.value = 'optimizeClose.customerInfoNotFound';
        }
    } catch (error) {
        redirectMessage.value = 'optimizeClose.errorTryAgain';
    } finally {
        // リダイレクトが完了しなかった場合のために、タイムアウトを設定
        setTimeout(() => {
            redirecting.value = false;
            redirectMessage.value = '';
        }, 5000); // 5秒後にメッセージを消す
    }
};

const getLatestSubscriptionStatus = async () => {
    try {
        const uid = currentUser.value.uid;
        const customerDocRef = doc(db, 'customers', uid);
        const customerDoc = await getDoc(customerDocRef);

        if (customerDoc.exists()) {
            const subscriptionsCollectionRef = collection(db, `customers/${uid}/subscriptions`);
            const q = query(subscriptionsCollectionRef, orderBy('created', 'desc'), limit(1));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const latestSubscriptionDoc = querySnapshot.docs[0];
                planStatus.value = latestSubscriptionDoc.get('status');
                if (planStatus.value === 'past_due') {
                    console.error('決済エラーです。引き続きPlusプランをご利用いただく場合は、契約情報からクレジットカード情報の更新をお願いいたします。');
                }
            } else {
                console.log(`No subscriptions found for UID ${uid}.`);
                planStatus.value = 'NoSubscriptions';
            }
        } else {
            console.log(`No customer document found for UID ${uid}.`);
            planStatus.value = null;
        }
    } catch (e) {
        console.error('Error getting latest subscription status:', e);
        planStatus.value = null;
    }
};

const fetchData = async () => {
    if (currentUser.value) {
        try {
            const uid = currentUser.value.uid;

            // ユーザードキュメントリファレンスを取得
            const userDocRef = doc(db, 'users', uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                // Fetch count from /users/{uid}/planning/{year-month}
                const currentDate = new Date();
                const yearMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
                const planningDocRef = doc(db, `users/${uid}/planning/${yearMonth}`);
                const planningDoc = await getDoc(planningDocRef);

                if (planningDoc.exists()) {
                    const planningCount = planningDoc.data().count;

                    // You can add the count to the data or set it to a separate variable
                    planningCountField.value = planningCount;
                } else {
                    console.log('No upload document found for the current month.');
                }
            } else {
                console.log('No such document!!');
                await setDoc(userDocRef, { createdAt: Timestamp.now() });
                console.log('User document created.');
                // プロジェクトコレクションリファレンスを取得
            }
        } catch (error) {
            console.error('Error fetching data from Firestore:', error);
        }
    } else {
        stripeId.value = '';
        console.log('No user is logged in.');
    }
};

// 新しく追加する変数
const isAlgorithmSelectionVisible = ref(false);
const algorithms = ref([]);
const selectedAlgorithm = ref({ id: "gs2ec4N6Y1nMAY5c4EwW", name: "Default" });

// selectedAlgorithmの変更を監視し、フォームデータを更新する
watch(selectedAlgorithm, (newValue) => {
    if (newValue) {
        if (newValue.demandData) {
            const unescapedDemandData = newValue.demandData.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
            demandData.value = unescapedDemandData;
            validateData(unescapedDemandData, 'demand');
        }
        if (newValue.productMasterData) {
            const unescapedProductMasterData = newValue.productMasterData.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
            productMasterData.value = unescapedProductMasterData;
            validateData(unescapedProductMasterData, 'productMaster');
        }
        if (newValue.machineResourcesData) {
            const unescapedMachineResourcesData = newValue.machineResourcesData.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
            machineResourcesData.value = unescapedMachineResourcesData;
            validateData(unescapedMachineResourcesData, 'machineResources');
        }
    }
});

const validateData = (data, type) => {
    let errorMessage = '';

    // エスケープされた特殊文字を実際の文字に変換
    const unescapedData = data.replace(/\\n/g, '\n').replace(/\\t/g, '\t');

    const lines = unescapedData.split('\n');
    if (lines.length < 2) {
        errorMessage = rt(tm('optimizeClose.validateData.minimumTwoLines'));
    } else {
        const headerColumns = lines[0].split('\t');
        if (headerColumns.length < 2) {
            errorMessage = rt(tm('optimizeClose.validateData.minimumTwoColumns'));
        } else {
            for (let i = 1; i < lines.length; i++) {
                const columns = lines[i].split('\t');
                if (columns.length !== headerColumns.length) {
                    errorMessage = rt(tm('optimizeClose.validateData.columnMismatch', { lineNumber: i + 1 }));
                    break;
                }
            }
        }
    }

    if (type === 'demand') demandError.value = errorMessage;
    else if (type === 'productMaster') productMasterError.value = errorMessage;
    else if (type === 'machineResources') machineResourcesError.value = errorMessage;
};

const sortedAlgorithms = computed(() => {
    return [...algorithms.value].sort((a, b) => {
        // まずはお気に入りでソート
        if (a.isFavorite && !b.isFavorite) return -1;
        if (!a.isFavorite && b.isFavorite) return 1;

        // お気に入りが同じ場合は作成日時でソート（より古いものが先）
        if (a.createdAt && b.createdAt) {
            return a.createdAt.toDate() - b.createdAt.toDate();
        }

        // createdAt が存在しない場合の処理
        if (a.createdAt && !b.createdAt) return -1;
        if (!a.createdAt && b.createdAt) return 1;

        // どちらも createdAt がない場合は ID でソート
        return a.id.localeCompare(b.id);
    });
});

// 新しく追加する関数
const showAlgorithmSelection = async () => {
    isAlgorithmSelectionVisible.value = true;
    await fetchAlgorithms();
};

const closeAlgorithmSelection = () => {
    isAlgorithmSelectionVisible.value = false;
};
const selectAlgorithm = (algorithm) => {
    if (algorithm.PremiumOnly && !canSelectPremiumAlgorithm.value) {
        alert(rt(tm('optimizeClose.selectAlgorithm.premiumOnlyAlert')));
        return;
    }
    selectedAlgorithm.value = algorithm;
    closeAlgorithmSelection();
};

const canSelectPremiumAlgorithm = computed(() => {
    return planStatus.value === "active" || planStatus.value === "trialing" || planStatus.value === "past_due";
});

const { locale } = useI18n();

const fetchAlgorithms = async () => {
    try {
        const algorithmsCollection = collection(db, 'algorithms');
        const algorithmsSnapshot = await getDocs(algorithmsCollection);
        const fetchedAlgorithms = algorithmsSnapshot.docs
            .map(doc => ({
                id: doc.id,
                ...doc.data(),
                isFavorite: false,
                description: locale.value === 'en'
                    ? doc.data().description_en || doc.data().description
                    : locale.value === 'de'
                        ? doc.data().description_de || doc.data().description
                        : doc.data().description
            }))
            .filter(algorithm => algorithm.isAvailable === true);

        if (currentUser.value) {
            const userFavoritesDoc = await getDoc(doc(db, 'users', currentUser.value.uid, 'favorites', 'algorithms'));
            if (userFavoritesDoc.exists()) {
                const favorites = userFavoritesDoc.data().favoriteAlgorithms || [];
                fetchedAlgorithms.forEach(algorithm => {
                    algorithm.isFavorite = favorites.includes(algorithm.id);
                });
            }
        }

        algorithms.value = fetchedAlgorithms;
    } catch (error) {
        console.error('Error fetching algorithms:', error);
    }
};

// Watch for changes in the locale and update the descriptions accordingly
watch(locale, (newLocale) => {
    algorithms.value = algorithms.value.map(algorithm => ({
        ...algorithm,
        description: newLocale === 'en' ? algorithm.description_en || algorithm.description : algorithm.description
    }));
});

const toggleFavorite = async (algorithm) => {
    if (!currentUser.value) return;

    try {
        const userFavoritesRef = doc(db, 'users', currentUser.value.uid, 'favorites', 'algorithms');
        const userFavoritesDoc = await getDoc(userFavoritesRef);
        let favorites = userFavoritesDoc.exists() ? userFavoritesDoc.data().favoriteAlgorithms || [] : [];

        if (algorithm.isFavorite) {
            favorites = favorites.filter(id => id !== algorithm.id);
        } else {
            favorites.push(algorithm.id);
        }

        await setDoc(userFavoritesRef, { favoriteAlgorithms: favorites }, { merge: true });
        algorithm.isFavorite = !algorithm.isFavorite;
    } catch (error) {
        console.error('Error toggling favorite:', error);
    }
};

onMounted(() => {
    // Firebase 認証状態の監視
    onAuthStateChanged(firebaseAuth, async (user) => {
        if (user) {
            currentUser.value = user;
            userEmail.value = user.email;
            await fetchData();
            await getLatestSubscriptionStatus();
            await fetchAlgorithms(); // 新しく追加した制約条件取得関数
        } else {
            currentUser.value = null;
            userEmail.value = '';
            algorithms.value = []; // 制約条件リストをクリア
            router.push(`/${currentLang.value}/login`);
        }
    });
});

const analytics = getAnalytics();

const optimize = async () => {
    isCalculating.value = true;
    optimizationResult.value = null;

    // 最適化開始イベントを記録
    logEvent(analytics, 'optimization_started', {
        timestamp: new Date().toISOString()
    });

    const formData = new FormData();
    formData.append('demand', demandData.value);
    formData.append('product_master', productMasterData.value);
    formData.append('machine_resources', machineResourcesData.value);

    // currentUserの取得
    const uid = currentUser.value.uid; // 現在のユーザーのUIDを取得
    formData.append('userId', uid);
    const selectedAlgorithmId = selectedAlgorithm.value.id
    formData.append('selectedAlgorithmId', selectedAlgorithmId);

    try {
        const response = await fetch('https://us-central1-aligngengpt.cloudfunctions.net/https_flask_app/optimize_close', {
            //     const response = await fetch('http://localhost:5001/optimize_close', {
            method: 'POST',
            body: formData
        });
        const data = await response.json();
        optimizationResult.value = data;

        // 最適化成功イベントを記録
        logEvent(analytics, 'optimization_completed', {
            status: 'success',
            AlgorithmId: selectedAlgorithm.value.id,
            timestamp: new Date().toISOString()
        });
    } catch (error) {
        console.error('Error:', error);
        optimizationResult.value = { error: rt(tm('optimizeClose.errorOccurred')) };

        // 最適化失敗イベントを記録
        logEvent(analytics, 'optimization_completed', {
            status: 'error',
            error_message: error.message,
            timestamp: new Date().toISOString()
        });
    } finally {
        isCalculating.value = false;
    }
};
</script>

<style scoped>
.container my-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    margin-top: 5rem;
    /* Adjusts margin top */
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    margin-bottom: 2rem;
    /* Adds more space between header and content */
}

.logo {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.header h1 {
    margin-right: auto;
    font-size: 1.2em;
    color: #000000;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 2;
}

.modern-menu {
    position: absolute;
    top: 40px;
    right: 0;
    width: 200px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu-width-50 {
    width: 50%;
}

.modern-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.modern-menu li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modern-menu li:hover {
    background-color: #f0f0f0;
}

.menu-link {
    text-decoration: none;
    color: black;
}

.user-email {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
}

.trialing,
.plusPlan {
    pointer-events: none;
}

.fas.fa-bars.fa-lg {
    cursor: pointer;
}

.loading-container {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

.loading-text {
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-text i {
    margin-right: 8px;
    color: #ff9800;
}

.bar-container {
    width: 100%;
    background-color: #fff3e0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
}

.bar {
    height: 6px;
    background: linear-gradient(90deg, #ff9800 0%, #ffb74d 100%);
    border-radius: 10px;
    animation: fill-bar 2s ease-out infinite;
    position: relative;
    overflow: hidden;
}

.bar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 100%);
    animation: shimmer 1.5s infinite;
}

@keyframes fill-bar {
    0% {
        width: 0;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 100%;
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
}

.table-responsive {
    overflow-x: auto;
    overflow-y: auto;
}

.algorithm-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #dee2e6;
}

.algorithm-table th,
.algorithm-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    vertical-align: top;
}

.algorithm-table th {
    background-color: #f8f9fa;
    font-weight: bold;
    border-bottom: 2px solid #dee2e6;
}

.algorithm-table tr:last-child td {
    border-bottom: none;
}

.algorithm-table th:last-child,
.algorithm-table td:last-child {
    border-right: none;
}

.algorithm-table tbody tr:hover {
    background-color: #f5f5f5;
}

.name-column {
    width: 20%;
}

.description-column {
    width: 60%;
}

.select-column {
    width: 10%;
}

.favorite-column {
    width: 5%;
}

.name-column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description-column {
    white-space: normal;
    word-wrap: break-word;
}

.select-column,
.favorite-column {
    text-align: center;
}

.select-btn {
    padding: 2px 5px;
    font-size: 0.75rem;
    white-space: nowrap;
}

.favorite-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    transition: transform 0.2s ease;
}

.favorite-btn:hover {
    transform: scale(1.2);
}

.favorite-btn .fa-star {
    color: #6c757d;
    transition: color 0.3s ease;
}

.favorite-btn .fa-star.favorite {
    color: #ffc107;
}

.favorite-btn:hover .fa-star {
    color: #ffd54f;
}

.algorithm-selection-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.algorithm-selection-container button {
    margin-right: 1rem;
}

.selected-algorithm {
    font-weight: bold;
    color: #007bff;
}

.redirect-message {
    background-color: #fff3e0;
    color: #ff9800;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
}

.premium-icon {
    color: #ffd700;
    margin-left: 5px;
    font-size: 0.9em;
}
</style>