import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '@/firebase';
import i18n from '@/i18n';
import ServiceTop from '@/components/ServiceTop.vue';
import AuthHandler from '@/components/AuthHandler.vue';
import ChangeEmail from '@/components/ChangeEmail.vue';
import UserLogin from '@/components/UserLogin.vue';
import UserRegistration from '@/components/UserRegistration.vue';
import RequestPasswordReset from '@/components/RequestPasswordReset.vue';
import ConfirmPasswordReset from '@/components/ConfirmPasswordReset.vue';
import OptimizeClose from '@/components/OptimizeClose.vue';
import AddAlgorithm from '@/components/AddAlgorithm.vue';
import NotFound from '@/components/NotFound.vue'; // 404ページのコンポーネントをインポート

const routes = [
    {
        path: '/:lang',
        component: {
            template: '<router-view></router-view>'
        },
        children: [
            {
                path: '',
                name: 'ServiceTop',
                component: ServiceTop,
                meta: { titleKey: 'routerTitles.serviceTop' }
            },
            {
                path: 'auth-action',
                name: 'AuthAction',
                component: AuthHandler
            },
            {
                path: 'login',
                name: 'UserLogin',
                component: UserLogin,
                meta: { titleKey: 'routerTitles.login' }
            },
            {
                path: 'change-email',
                name: 'ChangeEmail',
                component: ChangeEmail,
                meta: { requiresAuth: true, titleKey: 'routerTitles.changeEmail' }
            },
            {
                path: 'register',
                name: 'UserRegistration',
                component: UserRegistration,
                meta: { titleKey: 'routerTitles.register' }
            },
            {
                path: 'forgot-password',
                name: 'RequestPasswordReset',
                component: RequestPasswordReset,
                meta: { titleKey: 'routerTitles.forgotPassword' }
            },
            {
                path: 'Confirm-password-reset',
                name: 'ConfirmPasswordReset',
                component: ConfirmPasswordReset,
                meta: { titleKey: 'routerTitles.confirmPasswordReset' }
            },
            {
                path: 'app',
                name: 'OptimizeClose',
                component: OptimizeClose,
                meta: { requiresAuth: true, requiresVerifiedEmail: true, titleKey: 'routerTitles.optimizeClose' }
            },
            {
                path: 'AddAlgorithm',
                name: 'AddAlgorithm',
                component: AddAlgorithm,
                meta: { requiresAuth: true, titleKey: 'routerTitles.addAlgorithm' }
            },
            {
                path: ':pathMatch(.*)*',
                name: 'NotFound',
                component: NotFound,
                meta: { titleKey: 'routerTitles.notFound' }
            }
        ]
    }
];

// このルートを修正
const defaultRoute = {
    path: '/',
    redirect: to => {
        const userLang = navigator.language.split('-')[0];
        const availableLangs = i18n.global.availableLocales;
        const lang = availableLangs.includes(userLang) ? userLang : i18n.global.fallbackLocale.value;
        return `/${lang}${to.fullPath}`;
    }
};

routes.push(defaultRoute);

const router = createRouter({
    history: createWebHistory(),
    routes
});


router.beforeEach(async (to, from, next) => {
    const supportedLanguages = i18n.global.availableLocales;
    let lang = to.params.lang;

    if (!lang) {
        // URLに言語パラメータがない場合、ブラウザの言語設定を使用
        const browserLang = navigator.language.split('-')[0];
        lang = supportedLanguages.includes(browserLang) ? browserLang : i18n.global.fallbackLocale.value;
        return next(`/${lang}${to.fullPath}`);
    }

    if (!supportedLanguages.includes(lang)) {
        lang = i18n.global.fallbackLocale.value;
        return next(`/${lang}${to.fullPath.replace(/^\/[^/]+/, '')}`);
    }

    i18n.global.locale.value = lang;
    document.documentElement.lang = lang;

    const user = auth.currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresVerifiedEmail = to.matched.some(record => record.meta.requiresVerifiedEmail);

    if (requiresAuth && !user) {
        return next(`/${lang}/login`);
    }

    if (requiresVerifiedEmail && user && !user.emailVerified) {
        return next(`/${lang}/login`);
    }

    if (to.meta.titleKey) {
        document.title = i18n.global.t(to.meta.titleKey);
    }

    next();
});

export default router;