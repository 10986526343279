<script>
import { getAuth, applyActionCode } from "firebase/auth";
import { useRouter, useRoute } from 'vue-router';
import { computed } from 'vue';

export default {
  name: "AuthHandler",
  mounted() {
    this.handleAuthAction();
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const currentLang = computed(() => route.params.lang || 'en');

    const handleAuthAction = () => {
      const params = new URLSearchParams(window.location.search);
      const mode = params.get('mode');
      const oobCode = params.get('oobCode');

      switch (mode) {
        case 'verifyEmail':
          verifyEmail(oobCode);
          break;
        case 'resetPassword':
          router.push({
            path: `/${currentLang.value}/Confirm-password-reset`,
            query: { oobCode }
          });
          break;
        case 'verifyAndChangeEmail':
          verifyAndChangeEmail(oobCode);
          break;
        default:
          router.push(`/${currentLang.value}/`);
          break;
      }
    };

    const verifyEmail = (oobCode) => {
      const auth = getAuth();
      applyActionCode(auth, oobCode)
        .then(() => {
          console.log('Email verified successfully!');
          router.push(`/${currentLang.value}/app`);
        })
        .catch((error) => {
          console.error('Error during email verification:', error);
          router.push(`/${currentLang.value}/login`);
        });
    };

    const verifyAndChangeEmail = (oobCode) => {
      const auth = getAuth();
      applyActionCode(auth, oobCode)
        .then(() => {
          console.log('Email address successfully updated.');
          localStorage.setItem('emailChangeSuccess', 'true');
          router.push(`/${currentLang.value}/app`);
        })
        .catch((error) => {
          console.error('Error during email change:', error);
          router.push(`/${currentLang.value}/`);
        });
    };

    return {
      handleAuthAction,
      verifyEmail,
      verifyAndChangeEmail
    };
  }
};
</script>
