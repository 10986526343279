import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getDatabase } from 'firebase/database';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAnrzbViMwdkrdUSajDZ1Lpr0TcGrwy40Q",
  authDomain: "aligngengpt.firebaseapp.com",
  projectId: "aligngengpt",
  storageBucket: "aligngengpt.appspot.com",
  messagingSenderId: "152808833992",
  appId: "1:152808833992:web:b352fe87938a5f665e76fd",
  measurementId: "G-8KPLT3S1KE"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const database = getDatabase(app);
// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().

// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LdoAvEpAAAAAGXrzonnBv4CZ0ffqUKSWjeyA3nq'),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export { auth, analytics, app, db, functions,database,appCheck  };