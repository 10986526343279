<template>
    <div>
        <h2>{{ rt(tm('changeEmail.title')) }}</h2>
        <form @submit.prevent="handleChangeEmail">
            <input type="email" id="newEmail" v-model="newEmail" :placeholder="rt(tm('changeEmail.newEmailPlaceholder'))" required />

            <input type="password" id="password" v-model="password" :placeholder="rt(tm('changeEmail.passwordPlaceholder'))" required />

            <button type="submit">{{ rt(tm('changeEmail.submitButton')) }}</button>
        </form>
        <router-link :to="`/${$route.params.lang || 'en'}/app`">{{ rt(tm('changeEmail.backToHome')) }}</router-link>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, verifyBeforeUpdateEmail } from "firebase/auth";

export default {
    setup() {
        const { tm, rt } = useI18n();
        const newEmail = ref("");
        const password = ref("");

        const handleChangeEmail = async () => {
            const auth = getAuth();
            const user = auth.currentUser;

            // Check if the user signed in with email/password
            const isEmailPasswordUser = user.providerData.some(
                (provider) => provider.providerId === EmailAuthProvider.PROVIDER_ID
            );

            if (!isEmailPasswordUser) {
                alert(rt(tm('changeEmail.emailPasswordOnlyAlert')));
                return;
            }

            const credential = EmailAuthProvider.credential(user.email, password.value);

            try {
                // Reauthenticate the user
                await reauthenticateWithCredential(user, credential);

                // Send verification email to the new email address
                await verifyBeforeUpdateEmail(user, newEmail.value);

                alert(rt(tm('changeEmail.verificationEmailSent')));
            } catch (error) {
                console.error("Error:", error);
                if (error.code === 'auth/invalid-credential') {
                    alert(rt(tm('changeEmail.invalidCredentials')));
                } else {
                    alert(error.message);
                }
            }
        };

        return {
            newEmail,
            password,
            handleChangeEmail,
            rt,
            tm
        };
    }
};
</script>

<style scoped>
div {
    background-color: #fff;
    width: 100%;
    max-width: 500px;
    margin: 30px auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="email"],
input[type="password"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

input[type="email"]:focus,
input[type="password"]:focus {
    border-color: #f57c00;
    outline: none;
}

button {
    background-color: #fff;
    color: #5F6368;
    border: 1px solid #ccc;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 10px;
}

button:hover {
    background-color: #f57c00;
    color: #fff;
    border-color: #f57c00;
}

p {
    color: #f57c00;
    text-align: center;
    margin-top: 10px;
}
</style>