<template>
  <div>
    <div class="mb-3">
      <label for="machineFilter" class="form-label">{{ rt(tm('machineUsageChart.resourceFilter')) }}：</label>
      <select id="machineFilter" v-model="selectedMachine" class="form-select">
        <option v-for="machine in machines" :key="machine" :value="machine">
          {{ machine }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <strong>{{ rt(tm('machineUsageChart.averageUtilization')) }}: {{ averageUtilization }}</strong>
    </div>
    <div ref="chartContainer" style="width: 100%; height: 400px;"></div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import * as echarts from 'echarts';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { tm, rt } = useI18n();
    const chartContainer = ref(null);
    let chart = null;
    const selectedMachine = ref(rt(tm('machineUsageChart.total')));

    const machines = computed(() => {
      console.log('Chart Data:', props.chartData);
      return props.chartData && props.chartData.series 
        ? [rt(tm('machineUsageChart.total')), ...props.chartData.series.map(s => s.name)]
        : [rt(tm('machineUsageChart.total'))];
    });

    const filteredData = computed(() => {
      if (!props.chartData || !props.chartData.dates || !props.chartData.series) {
        console.error('Invalid chart data:', props.chartData);
        return { dates: [], usage: [], resource: [] };
      }

      if (selectedMachine.value === rt(tm('machineUsageChart.total'))) {
        const totalUsage = props.chartData.dates.map((_, index) => 
          props.chartData.series.reduce((sum, series) => sum + (series.data[index]?.value || 0), 0)
        );
        const totalResource = props.chartData.dates.map((_, index) => 
          props.chartData.series.reduce((sum, series) => sum + (series.resourceData?.[index] || 0), 0)
        );
        return {
          dates: props.chartData.dates,
          usage: totalUsage,
          resource: totalResource
        };
      } else {
        const machineData = props.chartData.series.find(s => s.name === selectedMachine.value);
        if (!machineData) {
          console.error('Selected machine data not found:', selectedMachine.value);
          return { dates: [], usage: [], resource: [] };
        }
        return {
          dates: props.chartData.dates,
          usage: machineData.data.map(d => d.value),
          resource: machineData.resourceData
        };
      }
    });

    const averageUtilization = computed(() => {
      if (!filteredData.value || !filteredData.value.usage || !filteredData.value.resource) {
        console.error('Invalid filtered data:', filteredData.value);
        return 'N/A';
      }
      const totalUsage = filteredData.value.usage.reduce((sum, value) => sum + value, 0);
      const totalResource = filteredData.value.resource.reduce((sum, value) => sum + value, 0);
      console.log('Total Usage:', totalUsage, 'Total Resource:', totalResource);
      if (totalResource === 0) return '0.00%';
      const utilization = (totalUsage / totalResource) * 100;
      return `${utilization.toFixed(2)}%`;
    });

    const updateChart = () => {
      if (!filteredData.value || !filteredData.value.dates) {
        console.error('Cannot update chart: Invalid data');
        return;
      }

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: [rt(tm('machineUsageChart.usage')), rt(tm('machineUsageChart.resourceLimit'))]
        },
        xAxis: {
          type: 'category',
          data: filteredData.value.dates,
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'value',
          name: rt(tm('machineUsageChart.quantity')),
          min: 0,
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: [
          {
            name: rt(tm('machineUsageChart.usage')),
            type: 'bar',
            data: filteredData.value.usage,
            itemStyle: {
              color: function(params) {
                const usageRate = params.data / filteredData.value.resource[params.dataIndex];
                if (usageRate < 0.5) return '#91cc75';
                if (usageRate < 0.8) return '#fac858';
                return '#ee6666';
              }
            }
          },
          {
            name: rt(tm('machineUsageChart.resourceLimit')),
            type: 'scatter',
            data: filteredData.value.resource,
            symbolSize: 10,
            itemStyle: {
              color: '#5470c6'
            }
          }
        ]
      };

      chart.setOption(option);
    };

    onMounted(() => {
      chart = echarts.init(chartContainer.value);
      updateChart();
    });

    watch(() => props.chartData, updateChart, { deep: true });
    watch(selectedMachine, updateChart);

    // 言語が変更されたときにチャートを更新
    watch(() => tm('machineUsageChart.title'), updateChart);

    return {
      chartContainer,
      selectedMachine,
      machines,
      averageUtilization,
      rt,
      tm
    };
  }
};
</script>

<style scoped>
.machine-usage-chart {
  background-color: #ffffff;
  color: #333333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-label, .form-select, strong {
  color: #333333;
}
</style>