<template>
    <div class="service-top">
        <header>
            <div class="container">
                <div id="branding">
                    <h1><span class="highlight">ManufactureOptimizer</span></h1>
                </div>
                <div class="menu-toggle" @click="toggleMenu">
                    <i class="fas fa-bars"></i>
                </div>
                <nav :class="{ 'active': isMenuOpen }">
                    <ul>
                        <li>
                            <router-link :to="`/${$route.params.lang || 'en'}/register`">{{ $t('register')
                                }}</router-link>
                        </li>
                        <li>
                            <router-link :to="`/${$route.params.lang || 'en'}/login`">{{ $t('login') }}</router-link>
                        </li>
                        <li><a href="https://forms.gle/8xMSX68Aq8Mb94bDA">{{ $t('contactForm') }}</a></li>
                    </ul>
                </nav>
            </div>
        </header>

        <main>
            <section class="hero" id="home">
                <div class="hero-content">
                    <h1>{{ $t('heroTitle') }}</h1>
                    <p>{{ $t('heroDescription') }}</p>
                    <button class="button" @click="showDetails">{{ $t('tryDemo') }}</button>
                </div>
            </section>

            <section class="features" id="about">
                <div class="section-header">
                    <h2>{{ $t('featuresTitle') }}</h2>
                </div>
                <div class="boxes">
                    <div v-for="feature in features" :key="feature.key" class="box">
                        <img :src="feature.imageSrc" :alt="$t(`features.${feature.key}.title`)" loading="lazy">
                        <h3>{{ $t(`features.${feature.key}.title`) }}</h3>
                        <p>{{ $t(`features.${feature.key}.description`) }}</p>
                    </div>
                </div>
            </section>

            <section class="optimize-open" id="demo">
                <div class="section-header">
                    <br>
                    <h2>{{ $t('optimizationTitle') }}</h2>
                    <p>{{ $t('optimizationDescription') }}</p>
                    <p v-html="$t('optimizationGuide')"></p>
                    <div class="card"
                        style="margin: 0 auto; padding: 1rem; border: 1px solid #dee2e6; box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); background-color: rgba(255, 255, 255, 0.3);">
                        <p style="text-align: left; font-size: 1.2em;">
                            <strong>{{ $t('constraintsTitle') }}</strong>
                        </p>
                        <p style="text-align: left;" v-html="$t('constraintsDescription')"></p>
                    </div>
                </div>
                <div class="optimize-open-container">
                    <Suspense>
                        <template #default>
                            <OptimizeOpen />
                        </template>
                        <template #fallback>
                            <div>{{ $t('loading') }}</div>
                        </template>
                    </Suspense>
                </div>
            </section>

            <section class="faq" id="faq">
                <div class="section-header">
                    <h2>{{ t('faq.title') }}</h2>
                </div>
                <div class="faq-container">
                    <div v-for="(item, index) in faqItems" :key="index" class="faq-item">
                        <div class="faq-question" @click="toggleFaq(index)">
                            {{ rt(item.question) }}
                            <span class="faq-toggle">{{ item.isOpen ? '−' : '＋' }}</span>
                        </div>
                        <div class="faq-answer" v-show="item.isOpen" v-html="rt(item.answer)"></div>
                    </div>
                </div>
            </section>
        </main>
        <footer>
            <div class="container">
                <ul class="footer-links">
                    <li><a href="https://manufactureoptimizer.notion.site/92bf766fa6954ea39b697f4d4d0c62b9"
                            target="_blank">{{
                                $t('footer.termsOfService') }}</a></li>
                    <li><a href="https://manufactureoptimizer.notion.site/0fa40ed7e99a477aba267615ded6cdf4"
                            target="_blank">{{
                                $t('footer.privacyPolicy') }}</a></li>
                    <li><a href="https://manufactureoptimizer.notion.site/4283ce646c3c47a1946080b545fa27e0"
                            target="_blank">{{
                                $t('footer.commercialTransactions') }}</a></li>
                    <li><a href="https://forms.gle/8xMSX68Aq8Mb94bDA" target="_blank">{{ $t('footer.contactForm') }}</a>
                    </li>
                    <li>
                        <router-link :to="`/${$route.params.lang || 'en'}/login`">{{ $t('login') }}</router-link>
                    </li>
                </ul>
            </div>
        </footer>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const OptimizeOpen = defineAsyncComponent(() =>
    import('./OptimizeOpen.vue')
)
export default {
    name: 'ServiceTop',
    components: {
        OptimizeOpen
    },
    data() {
        return {
            faqItems: [],
            isMenuOpen: false,
            features: [
                {
                    key: 'algorithm',
                    imageSrc: '/images/1_illustration.webp',
                },
                {
                    key: 'realtime',
                    imageSrc: '/images/2_illustration.webp',
                },
                {
                    key: 'affordable',
                    imageSrc: '/images/3_illustration.webp',
                }
            ]
        }
    },
    watch: {
        '$i18n.locale': {
            immediate: true,
            handler() {
                this.loadFaqItems();
            }
        }
    },
    mounted() {
        this.preloadBackgroundImage();
        console.log('ブラウザの言語設定:', navigator.language || navigator.userLanguage);
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        tm(key) {
            return this.$tm(key);
        },
        rt(value) {
            return this.$rt(value);
        },
        loadFaqItems() {
            this.faqItems = this.tm('faq.items').map(item => ({
                ...item,
                isOpen: false
            }));
        },
        toggleFaq(index) {
            this.faqItems[index].isOpen = !this.faqItems[index].isOpen;
            // Force update to re-render the component
            this.$forceUpdate();
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        showDetails() {
            const optimizeSection = document.getElementById('demo');
            if (optimizeSection) {
                optimizeSection.scrollIntoView({ behavior: 'smooth' });
            }
        },
        preloadBackgroundImage() {
            const img = new Image()
            img.onload = () => {
                document.body.style.backgroundImage = "url('/images/background.webp')"
                document.body.classList.add('background-loaded')
            }
            img.src = '/images/background.webp'
        }
    },
    beforeUnmount() {
        document.body.style.backgroundImage = ''
        document.body.classList.remove('background-loaded')
    }
}
</script>

<style scoped>
.service-top {
    color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

header {
    background: rgba(80, 163, 162, 0.8);
    color: white;
    padding: 1rem 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
}

.container {
    width: 80%;
    margin: auto;
    overflow: visible;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.menu-toggle {
    display: none;
    cursor: pointer;
    font-size: 24px;
    color: #ffffff;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1002;
}

header a {
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
}

header ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

header li {
    padding: 0 20px;
}

header #branding h1 {
    margin: 0;
}

.highlight {
    color: #71ecec;
    font-weight: bold;
    font-size: 0.8em;
}

main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.hero {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    padding: 80px 0;
}

.hero-content {
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    padding: 3rem;
    border-radius: 10px;
    max-width: 800px;
    width: 90%;
    margin: auto;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.hero p {
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.button {
    display: inline-block;
    padding: 12px 24px;
    background: #27a3a2;
    border: none;
    color: #ffffff;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.button:hover {
    background: #1c7574;
}

.section-header {
    text-align: center;
    margin-bottom: 2rem;
    width: 80%;
    margin: 0 auto;
}

.section-header h2 {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #ffffff;
}

.features {
    width: 80%;
    margin: 80px auto;
    padding: 60px 0;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

.boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 5%;
}

.box {
    flex: 0 1 calc(33.333% - 40px);
    text-align: center;
    padding: 30px;
    background: rgba(230, 230, 230, 0.1);
    margin-bottom: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.box:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.box img {
    width: 130px;
    height: 100px;
    margin-bottom: 20px;
}

.box h3 {
    color: #71ecec;
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.box p {
    font-size: 1.1rem;
}

@media (max-width: 1024px) {
    .features {
        width: 90%;
    }

    .box {
        flex: 0 1 calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .menu-toggle {
        display: block;
    }

    nav {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: rgba(80, 163, 162, 0.9);
        padding: 20px;
        z-index: 1001;
    }

    nav.active {
        display: block;
    }

    nav ul {
        flex-direction: column;
    }

    nav li {
        margin: 10px 0;
    }

    #branding {
        flex-grow: 1;
    }

    .hero {
        padding: 60px 0;
    }

    .hero h1 {
        font-size: 2.5rem;
    }

    .hero p {
        font-size: 1.2rem;
    }

    .features {
        width: 95%;
        margin: 60px auto;
        padding: 40px 0;
    }

    .boxes {
        flex-direction: column;
        align-items: center;
    }

    .box {
        flex: 0 1 100%;
        max-width: 400px;
        margin-bottom: 30px;
    }
}

.optimize-open {
    width: 100%;
    margin: 80px 0;
    padding: 60px 0;
    background: rgba(255, 255, 255, 0.1);
}

.optimize-open-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
}

/* OptimizeOpenコンポーネント内のスタイルを上書きするためのグローバルスタイル */
:deep(.optimize-open-container > div) {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

@media (max-width: 768px) {
    .optimize-open {
        margin: 60px 0;
        padding: 40px 0;
    }

    .optimize-open-container {
        width: 95%;
    }
}

.faq {
    width: 100%;
    margin: 80px 0;
    padding: 60px 0;
    background: rgba(255, 255, 255, 0.1);
}

.faq-container {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
}

.faq-item {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    overflow: hidden;
}

.faq-question {
    padding: 15px 20px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-toggle {
    font-size: 1.2em;
}

.faq-answer {
    padding: 0 20px 15px;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .faq-container {
        width: 95%;
    }
}

/* 背景画像のスタイルを追加 */
:global(body) {
    background-color: #f0f0f0;
    background-image: none;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    transition: background-image 0.5s ease-in-out;
}

.footer-links {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    padding: 0;
}

.footer-links li {
    margin: 0 10px;
}

.footer-links a {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 600px) {
    .footer-links {
        flex-direction: column;
        align-items: center;
    }

    .footer-links li {
        margin: 5px 0;
    }
}

nav ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

nav li {
    list-style-type: none;
    margin-right: 20px;
}

nav li:last-child {
    margin-right: 0;
}

nav a {
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
}

.language-selector {
    position: relative;
    display: inline-block;
}

.language-selector select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    color: #396b54;
    font-size: 14px;
    padding: 8px 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.language-selector select:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.language-selector select::-ms-expand {
    display: none;
}

@media (max-width: 768px) {
    .language-selector {
        margin-top: 10px;
    }
}
</style>