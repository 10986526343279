<template>
  <div class="not-found-container">
    <h1>404</h1>
    <p>{{ rt(tm('notFound.message')) }}</p>
    <router-link :to="`/${$route.params.lang || 'en'}/`">{{ rt(tm('notFound.backToHome')) }}</router-link>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { tm, rt } = useI18n();
</script>

<style scoped>
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #fff3e0; 
  color: #ff6f00;
  text-align: center;
}

h1 {
  font-size: 6em;
  font-weight: bold;
  color: #fb8c00;
}

p {
  font-size: 1.5em;
}

a {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #ff9800;  
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

a:hover {
  background-color: #ef6c00; 
}
</style>