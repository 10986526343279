<template>
  <div class="card mb-4">
    <div class="card-header" :class="headerClass">
      <h2 class="h5 mb-0">{{ t(`dataInput.${id}.title`) }}</h2>
    </div>
    <div class="card-body">
      <div class="mb-3">
        <label :for="id" class="form-label">{{ t('dataInput.uploadLabel') }}</label>
        <input
          type="file"
          class="form-control"
          :id="id"
          accept=".tsv, .txt"
          @change="handleFileInput"
        >
      </div>
      <div class="table-responsive">
        <div class="editable-table" @input="updateData">
          <table class="table table-sm table-striped" v-if="tableData.length">
            <thead>
              <tr>
                <th v-for="(header, index) in tableData[0]" :key="index" class="header-cell">
                  <div contenteditable="true" @input="updateCell(0, index, $event)">{{ header }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in tableData.slice(1)" :key="rowIndex">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex" class="data-cell">
                  <div contenteditable="true" @input="updateCell(rowIndex + 1, cellIndex, $event)">{{ cell }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-3">
        <button type="button" class="btn btn-secondary" @click="resetData">{{ t('dataInput.resetButton') }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  id: String,
  modelValue: String
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

const tableData = ref([]);

const headerClass = computed(() => {
  switch (props.id) {
    case 'demandFile': return 'bg-primary text-white';
    case 'productMasterFile': return 'bg-success text-white';
    case 'machineResourcesFile': return 'bg-info text-white';
    default: return '';
  }
});

const handleFileInput = (event) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
    const content = e.target.result;
    tableData.value = tsvToArray(content);
    emitUpdatedData();
  };
  reader.readAsText(file);
};

const updateCell = (rowIndex, cellIndex, event) => {
  tableData.value[rowIndex][cellIndex] = event.target.textContent;
  emitUpdatedData();
};

const updateData = () => {
  // この関数は不要になりましたが、将来の拡張性のために残しておきます
};

const tsvToArray = (tsv) => {
  return tsv.trim().split('\n').map(line => line.split('\t'));
};

const arrayToTsv = (array) => {
  return array.map(row => row.join('\t')).join('\n');
};

const emitUpdatedData = () => {
  emit('update:modelValue', arrayToTsv(tableData.value));
};

const resetData = () => {
  tableData.value = [];
  emitUpdatedData();
};

watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    tableData.value = tsvToArray(newValue);
  }
}, { immediate: true });
</script>


<style scoped>
.table-responsive {
  overflow-x: auto;
  max-width: 100%;
}

.editable-table {
  max-height: 400px;
  overflow-y: auto;
}

table {
  width: 100%;
  table-layout: auto;
}

.header-cell, .data-cell {
  white-space: nowrap;
  padding: 0.5rem;
  min-width: 100px;
}

.header-cell {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 1;
}

@media (max-width: 768px) {
  .header-cell, .data-cell {
    min-width: 80px;
  }
}
</style>