<template>
  <div v-if="result" class="mt-4">
    <div v-if="result.error" class="alert alert-danger" role="alert">
      {{ result.error }}
    </div>
    <template v-else>
      <div v-for="(data, title) in resultData" :key="title" class="mb-4">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h3>{{ rt(tm(`resultDisplay.${title}`)) }}</h3>
          <div class="btn-group" role="group">
            <button @click="downloadCSV(data, title)" class="btn btn-outline-primary">
              <i class="fas fa-download me-1"></i> {{ rt(tm('resultDisplay.csv')) }}
            </button>
            <button v-if="title === 'productionPlan'" @click="showProductionGraph" class="btn btn-outline-success">
              <i class="fas fa-chart-line me-1"></i> {{ rt(tm('resultDisplay.graph')) }}
            </button>
            <button v-if="title === 'resourceUsage'" @click="showMachineUsageGraph" class="btn btn-outline-success">
              <i class="fas fa-chart-bar me-1"></i> {{ rt(tm('resultDisplay.graph')) }}
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm result-table">
            <thead>
              <tr>
                <th class="index-column">{{ data[0][0] }}</th>
                <th v-for="(header, index) in data[0].slice(1)" :key="index" class="data-column">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in data.slice(1)" :key="rowIndex">
                <th class="index-column">{{ row[0] }}</th>
                <td v-for="(cell, cellIndex) in row.slice(1)" :key="cellIndex" class="data-column">
                  {{ cell !== undefined ? cell : '' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    
    <div v-if="showProductionGraphModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeProductionGraph">&times;</span>
        <h2>{{ rt(tm('resultDisplay.productionGraphTitle')) }}</h2>
        <div class="mb-3">
          <label for="productFilter" class="form-label">{{ rt(tm('resultDisplay.productFilter')) }}：</label>
          <select id="productFilter" v-model="selectedProduct" class="form-select">
            <option value="total">{{ rt(tm('resultDisplay.total')) }}</option>
            <option v-for="product in products" :key="product" :value="product">
              {{ product }}
            </option>
          </select>
        </div>
        <div class="graph-container">
          <CombinedChart :chartData="filteredChartData" />
        </div>
      </div>
    </div>

    <div v-if="showMachineUsageGraphModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeMachineUsageGraph">&times;</span>
        <h2>{{ rt(tm('resultDisplay.resourceUsageGraphTitle')) }}</h2>
        <div class="graph-container">
          <MachineUsageChart :chartData="machineUsageChartData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import CombinedChart from './CombinedChart.vue';
import MachineUsageChart from './MachineUsageChart.vue';

const props = defineProps({
  result: Object,
  demandData: String,
  machineResourcesData: String
});

const { tm, rt } = useI18n();

const showProductionGraphModal = ref(false);
const showMachineUsageGraphModal = ref(false);
const selectedProduct = ref('total');

const resultData = computed(() => {
  if (!props.result || props.result.error) return null;
  return {
    productionPlan: props.result.production_plan,
    inventoryPlan: props.result.inventory_plan,
    resourceUsage: props.result.machine_usage
  };
});

const products = computed(() => {
  if (!props.result || !props.result.production_plan) return [];
  return props.result.production_plan.slice(1).map(row => row[0]);
});

const chartData = computed(() => {
  if (!props.result || !props.demandData) return null;
  
  const dates = props.result.production_plan[0].slice(1);
  const products = props.result.production_plan.slice(1).map(row => row[0]);
  
  const productionData = products.map(product => ({
    name: `${product} ${rt(tm('resultDisplay.productionQuantity'))}`,
    type: 'bar',
    data: props.result.production_plan.find(row => row[0] === product).slice(1).map(Number)
  }));
  
  const inventoryData = products.map(product => ({
    name: `${product} ${rt(tm('resultDisplay.inventoryQuantity'))}`,
    type: 'line',
    data: props.result.inventory_plan.find(row => row[0] === product).slice(1).map(Number)
  }));
  
  const demandRows = props.demandData.trim().split('\n').slice(1);
  const demandData = products.map(product => {
    const demandRow = demandRows.find(row => row.startsWith(product));
    return {
      name: `${product} ${rt(tm('resultDisplay.demandQuantity'))}`,
      type: 'line',
      data: demandRow ? demandRow.split('\t').slice(1).map(Number) : []
    };
  });
  
  return {
    dates,
    series: [...productionData, ...inventoryData, ...demandData]
  };
});

const filteredChartData = computed(() => {
  if (!chartData.value) return null;

  if (selectedProduct.value === 'total') {
    const totalProduction = chartData.value.dates.map((_, index) => 
      chartData.value.series
        .filter(s => s.name.includes(rt(tm('resultDisplay.productionQuantity'))))
        .reduce((sum, series) => sum + series.data[index], 0)
    );
    const totalInventory = chartData.value.dates.map((_, index) => 
      chartData.value.series
        .filter(s => s.name.includes(rt(tm('resultDisplay.inventoryQuantity'))))
        .reduce((sum, series) => sum + series.data[index], 0)
    );
    const totalDemand = chartData.value.dates.map((_, index) => 
      chartData.value.series
        .filter(s => s.name.includes(rt(tm('resultDisplay.demandQuantity'))))
        .reduce((sum, series) => sum + series.data[index], 0)
    );

    return {
      dates: chartData.value.dates,
      series: [
        { name: rt(tm('resultDisplay.totalProductionQuantity')), type: 'bar', data: totalProduction },
        { name: rt(tm('resultDisplay.totalInventoryQuantity')), type: 'line', data: totalInventory },
        { name: rt(tm('resultDisplay.totalDemandQuantity')), type: 'line', data: totalDemand }
      ]
    };
  } else {
    return {
      dates: chartData.value.dates,
      series: chartData.value.series.filter(s => s.name.startsWith(selectedProduct.value))
    };
  }
});

const machineUsageChartData = computed(() => {
  if (!props.result || !props.result.machine_usage || !props.machineResourcesData) return null;

  const machineUsage = props.result.machine_usage;
  const resourceMaster = parseTSV(props.machineResourcesData);

  const dates = machineUsage[0].slice(1);
  const machines = machineUsage.slice(1).map(row => row[0]);

  const series = machines.map(machine => {
    const usageData = machineUsage.find(row => row[0] === machine).slice(1).map(Number);
    const resourceData = resourceMaster.find(row => row[0] === machine).slice(1).map(Number);

    return {
      name: machine,
      type: 'bar',
      data: dates.map((_, index) => ({
        value: usageData[index],
        itemStyle: {
          color: getColorByUsageRate(usageData[index], resourceData[index])
        }
      })),
      resourceData: resourceData
    };
  });

  return { dates, series };
});

const parseTSV = (tsvString) => {
  return tsvString.trim().split('\n').map(row => row.split('\t'));
};

const getColorByUsageRate = (usage, resource) => {
  const rate = usage / resource;
  if (rate < 0.5) return '#91cc75';  // 緑
  if (rate < 0.8) return '#fac858';  // 黄
  return '#ee6666';  // 赤
};

const showProductionGraph = () => {
  showProductionGraphModal.value = true;
};

const closeProductionGraph = () => {
  showProductionGraphModal.value = false;
};

const showMachineUsageGraph = () => {
  showMachineUsageGraphModal.value = true;
};

const closeMachineUsageGraph = () => {
  showMachineUsageGraphModal.value = false;
};

const downloadCSV = (data, title) => {
  const csvContent = data.map(row => row.join(',')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${rt(tm(`resultDisplay.${title}`))}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

</script>

<style scoped>
.result-table {
  width: 100%;
  table-layout: auto;
}

.result-table th,
.result-table td {
  white-space: nowrap;
  padding: 0.5rem;
}

.index-column {
  position: sticky;
  left: 0;
  background-color: #f8f9fa;
  z-index: 1;
}

.data-column {
  min-width: 80px;
}

.table-responsive {
  overflow-x: auto;
  max-width: 100%;
  max-height: 500px;
  overflow-y: auto;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 1200px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.graph-container {
  width: 100%;
  height: 600px;
}

@media (max-width: 768px) {
  .data-column {
    min-width: 60px;
  }
}

.form-label, .form-select, strong {
  color: #333333;
}

.btn-group .btn {
  margin-left: 5px;
}

.btn i {
  font-size: 0.9em;
}

@media (max-width: 576px) {
  .btn-group {
    display: flex;
    flex-direction: column;
  }
  
  .btn-group .btn {
    margin-left: 0;
    margin-top: 5px;
  }
}

.btn-outline-primary {
  color: #0056b3;
  border-color: #0056b3;
  background-color: #ffffff;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #0056b3;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
  background-color: #ffffff;
}

.btn-outline-success:hover {
  color: #ffffff;
  background-color: #28a745;
}

/* ボタンの遷移をスムーズにする */
.btn-outline-primary,
.btn-outline-success {
  transition: all 0.3s ease;
}

.btn {
  font-weight: bold;
}

.btn:hover {
  transform: scale(1.05);
}
</style>