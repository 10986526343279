<template>
  <div class="add-algorithm-container">
    <h2>Add New Algorithm</h2>
    <form @submit.prevent="addAlgorithm">
      <div class="form-group">
        <label for="name">Name:</label>
        <input v-model="algorithm.name" type="text" id="name" required>
      </div>
      <div class="form-group">
        <label for="description">Description:</label>
        <textarea v-model="algorithm.description" id="description" required></textarea>
      </div>
      <div class="form-group">
        <label for="description_en">Description_en:</label>
        <textarea v-model="algorithm.description_en" id="description_en" required></textarea>
      </div>
      <div class="form-group">
        <label for="description_de">Description_en:</label>
        <textarea v-model="algorithm.description_de" id="description_de" required></textarea>
      </div>
      <div class="form-group">
        <label for="demandData">Demand Data:</label>
        <textarea v-model="algorithm.demandData" id="demandData" required></textarea>
      </div>
      <div class="form-group">
        <label for="productMasterData">Product Master Data:</label>
        <textarea v-model="algorithm.productMasterData" id="productMasterData" required></textarea>
      </div>
      <div class="form-group">
        <label for="machineResourcesData">Machine Resources Data:</label>
        <textarea v-model="algorithm.machineResourcesData" id="machineResourcesData" required></textarea>
      </div>
      <button type="submit">Add Algorithm</button>
    </form>
    <p v-if="message" :class="{ 'success-message': isSuccess, 'error-message': !isSuccess }">
      {{ message }}
    </p>
  </div>
</template>

<script>
import { ref } from 'vue';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '@/firebase'; // Adjust this import based on your Firebase configuration file location

export default {
  name: 'AddAlgorithm',
  setup() {
    const algorithm = ref({
      name: '',
      description: '',
      description_en: '',
      description_de: '',
      demandData: '',
      productMasterData: '',
      machineResourcesData: '',
      isAvailable: false, 
      PremiumOnly: false 
    });
    const message = ref('');
    const isSuccess = ref(false);

    const addAlgorithm = async () => {
      try {
        const docRef = await addDoc(collection(db, 'algorithms'), {
          ...algorithm.value,
          createdAt: serverTimestamp()
        });
        console.log('Document written with ID: ', docRef.id);
        message.value = 'Algorithm added successfully!';
        isSuccess.value = true;
        // Reset the form
        algorithm.value = {
          name: '',
          description: '',
          description_en: '',
          description_de: '',
          demandData: '',
          productMasterData: '',
          machineResourcesData: '',
          isAvailable: false, 
          PremiumOnly: false 
        };
      } catch (error) {
        console.error('Error adding document: ', error);
        message.value = 'Error adding algorithm. Please try again.';
        isSuccess.value = false;
      }
    };

    return {
      algorithm,
      addAlgorithm,
      message,
      isSuccess
    };
  }
}
</script>
  
  <style scoped>
  .add-algorithm-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  textarea {
    height: 100px;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .success-message {
    color: green;
  }
  
  .error-message {
    color: red;
  }
  </style>