<template>
  <div>
    <h2>{{ rt(tm('userLogin.title')) }}</h2>
    <form @submit.prevent="login">
      <input type="email" v-model="email" :placeholder="rt(tm('userLogin.emailPlaceholder'))" required>
      <input type="password" v-model="password" :placeholder="rt(tm('userLogin.passwordPlaceholder'))" required>
      <p>
        <span v-html="rt(tm('userLogin.termsAgreement'))"></span>
      </p>
      <button class="submit-button" type="submit">{{ rt(tm('userLogin.loginButton')) }}</button>
    </form>
    <p class="or-separator">— {{ rt(tm('userLogin.or')) }} —</p>
    <button class="google-button" @click="loginWithGoogle">
      <svg aria-hidden="true" class="svg-icon iconGoogle" viewBox="0 0 18 18">
        <path
          d="M17.64 9.2045c0-.6381-.057-1.2518-.1638-1.8409H9v3.4818h4.8441c-.2086 1.125-.842 2.0782-1.7959 2.72v2.2568h2.9088c1.701-1.5664 2.6782-3.8742 2.6782-6.6177z"
          fill="#4285F4"></path>
        <path
          d="M9 18c2.43 0 4.4673-.8052 5.9562-2.1803l-2.9088-2.2568c-.8052.54-1.8376.8591-3.0474.8591-2.3406 0-4.3281-1.5795-5.0358-3.7104H.964v2.3318C2.4382 15.9836 5.4818 18 9 18z"
          fill="#34A853"></path>
        <path
          d="M3.9642 10.71c-.18-.54-.2822-1.125-.2822-1.71s.1023-1.17.2822-1.71V4.9582H.964c-1.17 2.0436-1.17 4.5736 0 6.6177l3.0002-2.3318z"
          fill="#FBBC05"></path>
        <path
          d="M9 3.5795c1.3218 0 2.5077.4545 3.4405 1.3454l2.5818-2.5818C13.4673.891 11.43 0 9 0 5.4818 0 2.4382 2.0164.964 4.9582l3.0002 2.3318C4.6719 5.1591 6.6594 3.5795 9 3.5795z"
          fill="#EB4335"></path>
      </svg>
      {{ rt(tm('userLogin.googleLogin')) }}
    </button>
    <p>
      {{ rt(tm('userLogin.noAccount')) }} 
      <router-link :to="`/${$route.params.lang || 'en'}/register`">{{ rt(tm('userLogin.register')) }}</router-link>
    </p>
    <p>
      <router-link :to="`/${$route.params.lang || 'en'}/forgot-password`">{{ rt(tm('userLogin.forgotPassword')) }}</router-link>
    </p>
    <p>
      <router-link :to="`/${$route.params.lang || 'en'}/`">{{ rt(tm('userLogin.backToTop')) }}</router-link>
    </p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { auth } from '@/firebase';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from 'firebase/auth';
import { computed } from 'vue';

const { tm, rt } = useI18n();
const router = useRouter();
const email = ref('');
const password = ref('');

const route = useRoute();
// 現在の言語を取得し、設定されていない場合は 'en' をデフォルトとして使用
const currentLang = computed(() => route.params.lang || 'en');

const login = async () => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
    if (userCredential.user.emailVerified) {
      router.push(`/${currentLang.value}/app`);
    } else {
      alert(rt(tm('userLogin.verifyEmailAlert')));
      await sendEmailVerification(userCredential.user);
    }
  } catch (error) {
    console.error("Error:", error);
    if (error.code === 'auth/invalid-credential') {
      alert(rt(tm('userLogin.invalidCredentials')));
    } else {
      alert(error.message);
    }
  }
};

const loginWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, new GoogleAuthProvider());
    const user = result.user;

    if (user.emailVerified) {
      router.push(`/${currentLang.value}/app`);
    } else {
      alert(rt(tm('userLogin.verifyEmailAlert')));
    }
  } catch (error) {
    console.error(error);
    alert(error.message);
  }
};

onMounted(() => {
  if (localStorage.getItem('emailChangeSuccess') === 'true') {
    alert(rt(tm('userLogin.emailChangeSuccess')));
    localStorage.removeItem('emailChangeSuccess');
  }
});
</script>


<style scoped>
.or-separator {
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  color: #555;
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px;
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #5F6368;
  border: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 10px;
  width: 52%;
  margin: 10px auto;
}


div {
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  margin: 30px auto;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

input[type="email"],
input[type="password"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #f57c00;
  outline: none;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #5F6368;
  border: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 10px;
}

button:hover {
  background-color: #f57c00;
  color: #fff;
  border-color: #f57c00;
}

.svg-icon {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

router-link {
  color: #f57c00;
  text-decoration: none;
}

router-link:hover {
  text-decoration: underline;
}

p {
  color: #666;
  text-align: center;
  font-size: 14px;
  margin-top: 15px;
}
</style>
