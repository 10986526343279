import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import router from './router'  // router/index.js からインポート
import i18n from './i18n';

const app = createApp(App)
app.use(router)  // Vue Router を使用
app.use(i18n);
app.mount('#app')