<template>
  <div>
    <h2>{{ rt(tm('confirmPasswordReset.title')) }}</h2>
    <form @submit.prevent="resetPassword">
      <input 
        type="password" 
        v-model="password" 
        :placeholder="rt(tm('confirmPasswordReset.newPasswordPlaceholder'))" 
        required
      >
      <p v-if="!validatePassword(password)">{{ rt(tm('confirmPasswordReset.passwordRequirements')) }}</p>
      <button type="submit">{{ rt(tm('confirmPasswordReset.resetButton')) }}</button>
    </form>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { auth } from '@/firebase';
import { confirmPasswordReset } from 'firebase/auth';

const { tm, rt } = useI18n();
const password = ref('');
const route = useRoute();
const router = useRouter();

// 現在の言語を取得し、設定されていない場合は 'en' をデフォルトとして使用
const currentLang = computed(() => route.params.lang || 'en');

const validatePassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\W_]{6,}$/;
  return regex.test(password);
};

const resetPassword = async () => {
  const oobCode = route.query.oobCode;

  if (!validatePassword(password.value)) {
    alert(rt(tm('confirmPasswordReset.invalidPasswordAlert')));
    return;
  }

  try {
    await confirmPasswordReset(auth, oobCode, password.value);
    alert(rt(tm('confirmPasswordReset.passwordResetSuccess')));
    router.push(`/${currentLang.value}/login`);
  } catch (error) {
    console.error('Error resetting password:', error);
    alert(rt(tm('confirmPasswordReset.passwordResetError')));
  }
};
</script>

<style scoped>
div {
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
}

h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

input[type="password"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

input[type="password"]:focus {
  border-color: #f57c00;
  outline: none;
}

p {
  color: #d32f2f;
  font-size: 14px;
  margin: 5px 0 15px;
}

button {
  background-color: #f57c00;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #e64a19;
}

button:disabled {
  background-color: #ccc;
}
</style>
