<template>
  <div id="app">
    <!-- メインコンテンツ -->
    <router-view></router-view>
    
    <!-- 言語選択コンポーネント（ServiceTop以外のページで表示） -->
    <LanguageSelector />
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import LanguageSelector from './components/LanguageSelector.vue';

const { t, locale } = useI18n();

const updateMetadataAndLang = () => {
  // メタデータの更新
  document.title = t('pageTitle');

  let metaDescription = document.querySelector('meta[name="description"]');
  if (!metaDescription) {
    metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    document.head.appendChild(metaDescription);
  }
  metaDescription.content = t('metaDescription');

  let metaKeywords = document.querySelector('meta[name="keywords"]');
  if (!metaKeywords) {
    metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    document.head.appendChild(metaKeywords);
  }
  metaKeywords.content = t('metaKeywords');

  let ldJsonScript = document.querySelector('script[type="application/ld+json"]');
  if (!ldJsonScript) {
    ldJsonScript = document.createElement('script');
    ldJsonScript.type = 'application/ld+json';
    document.head.appendChild(ldJsonScript);
  }
  ldJsonScript.textContent = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": t('schemaName'),
    "description": t('schemaDescription'),
    "applicationCategory": "BusinessApplication",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "JPY"
    },
    "provider": {
      "@type": "Organization",
      "name": "ManufactureOptimizer"
    }
  });

  // HTML langの更新
  document.documentElement.lang = locale.value;
};

// コンポーネントがマウントされたとき、および言語が変更されたときにメタデータとlang属性を更新
onMounted(updateMetadataAndLang);
watch(locale, updateMetadataAndLang);
</script>

<style>
/* 既存のスタイルはそのまま維持 */
</style>