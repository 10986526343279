<template>
  <div>
    <h2>{{ rt(tm('requestPasswordReset.title')) }}</h2>
    <p>{{ rt(tm('requestPasswordReset.instruction')) }}</p>
    <form @submit.prevent="sendResetEmail">
      <input type="email" v-model="email" :placeholder="rt(tm('requestPasswordReset.emailPlaceholder'))" required>
      <button type="submit">{{ rt(tm('requestPasswordReset.submitButton')) }}</button>
    </form>
    <p>
      <router-link :to="`/${$route.params.lang || 'en'}/login`">{{ rt(tm('requestPasswordReset.backToLogin')) }}</router-link>
    </p>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { auth } from '@/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

const { tm, rt } = useI18n();
const email = ref('');

const sendResetEmail = async () => {
try {
  await sendPasswordResetEmail(auth, email.value);
  alert(rt(tm('requestPasswordReset.successMessage')));
} catch (error) {
  console.error(rt(tm('requestPasswordReset.errorLogging')), error);
  alert(rt(tm('requestPasswordReset.errorMessage')));
}
};
</script>

<style scoped>
div {
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  margin: 30px auto;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

p {
  color: #666;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="email"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

input[type="email"]:focus {
  border-color: #f57c00;
  outline: none;
}

button {
  background-color: #f57c00;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #e64a19;
}

router-link {
  color: #f57c00;
  text-decoration: none;
}

router-link:hover {
  text-decoration: underline;
}
</style>
