// src/i18n.js
import { createI18n } from 'vue-i18n';

// ブラウザの言語設定を取得し、言語コードの部分だけを抽出
const browserLanguage = (navigator.language || navigator.userLanguage).split('-')[0];

// 対応している言語コードのリスト
const supportedLanguages = ['en', 'ja', 'de'];

// ブラウザの言語がサポートされているか確認し、デフォルト言語を設定
const defaultLocale = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';

const messages = {
  en: {
    welcome: "Welcome",
    register: "Create Account (Free)",
    login: "Login",
    contactForm: "Contact Form",
    heroTitle: "Achieve more efficient production planning",
    heroDescription: "Optimization solution for efficiency and productivity improvement",
    tryDemo: "Try Demo",
    featuresTitle: "Service Features",
    features: {
      algorithm: {
        title: "Optimization Algorithm",
        description: "Streamline production processes using optimization algorithms."
      },
      realtime: {
        title: "Real-time Data Analysis",
        description: "Analyze production data to support quick decision-making."
      },
      affordable: {
        title: "Affordable and Feature-rich",
        description: "Start from $0, with constraint conditions added based on your needs."
      }
    },
    optimizationTitle: "Try the Demonstration",
    optimizationDescription: "By creating an account, you will be able to use various constraints (free).",
    optimizationGuide: "For usage instructions, click <a href='https://manufactureoptimizer.notion.site/Instructions-for-Use-e11e22c3c050480da7766cc6cd252dbf?pvs=74' target='_blank' style='color: lightblue;'>here</a>.",
    constraintsTitle: "# Demonstration Content",
    constraintsDescription: "You are a production planner at a manufacturing plant. Please create a production plan while satisfying the following conditions.<br><br><b>Constraints</b><ul><li><b>Do not exceed machine resources</b>: The operating time of each machine must not exceed the maximum operating time per day.</li></ul><b>High Priority</b>:<br><ul><li><b>Minimize stock shortages</b>: Fulfill demand as much as possible.</li></ul><b>Medium Priority</b>:<br><ul><li><b>Minimize total production</b>: Reduce unnecessary production and produce only the minimum required.</li><li><b>Minimize product switches</b>: Minimize switching between different products being produced.</li><li><b>Minimize machine switches</b>: Minimize settings and adjustments required for machine operation.</li></ul><b>Low Priority</b>:<br><ul><li><b>Complete production early</b>: Finish all production as quickly as possible.</li></ul><b>Input Data</b><br><ul><li><b>Demand Information</b>: The demand quantity and delivery date for each product type.</li><li><b>Product Information</b>: Initial inventory quantity for each product type, machines capable of producing them.</li><li><b>Machine Information</b>: The producible quantity of each machine for each date.</li></ul><b>Output Data</b><br><ul><li><b>Production Plan</b>: The operating schedule for each machine (including start and end times for production of each product type and switching times between product types).</li><li><b>Inventory Variations</b>: Inventory increases and decreases based on the production plan.</li><li><b>Supply fulfillment of demand</b>: How much of the demand was met.</li></ul>",
    loading: "Loading...",
    faq: {
      title: "Frequently Asked Questions",
      items: [
        {
          question: "What kind of companies is this service suitable for?",
          answer: "It is suitable for companies of all sizes that need to optimize their production processes, especially in manufacturing. It provides great value to companies aiming for efficiency and cost reduction."
        },
        {
          question: "Can I use it for free?",
          answer: "Yes, you can create an account and perform calculations for free. However, free usage is limited to 20 calculations per month. By subscribing to the Plus plan ($7 per month), you can use it without limits."
        },
        {
          question: "Can I change the constraint conditions?",
          answer: "If you subscribe to the Plus plan, you can request additional constraint conditions. (Note: Depending on the content, some requests may be difficult to implement. Please first consult with us through the inquiry form. Consultation is free.)"
        },
        {
          question: "What are the payment methods?",
          answer: "We only accept credit card payments."
        },
        {
          question: "Can individuals use it?",
          answer: "Both corporations and individuals can use our service."
        }
      ]
    },
    footer: {
      termsOfService: "Terms of Service",
      privacyPolicy: "Privacy Policy",
      commercialTransactions: "Commercial Transaction Law",
      contactForm: "Contact Form",
      login: "Login"
    },
    pageTitle: "Simple Production Scheduler | Manufacture Optimizer",
    metaDescription: "A simple production scheduler that realizes efficiency and productivity improvement. We support problem-solving in manufacturing using AI technology.",
    metaKeywords: "production process optimization, manufacturing AI, productivity improvement, production scheduling, free production management software, low-cost production planning, factory efficiency, supply chain optimization, manufacturing DX, production management for SMEs, IoT production management, real-time production analysis, inventory optimization, delivery date compliance rate improvement, manufacturing cost reduction, free software",
    schemaName: "Manufacture Optimizer Simple Production Scheduler",
    schemaDescription: "A simple production scheduler that realizes efficiency and productivity improvement. We support problem-solving in manufacturing using AI technology.",
    chart: {
      title: '',
      yAxis: {
        name: 'Quantity'
      },
      legend: {
        'Total Production Quantity': 'Production Quantity',
        'Total Inventory Quantity': 'Inventory Quantity',
        'Total Demand Quantity': 'Demand Quantity'
      }
    },
    dataInput: {
      uploadLabel: 'Upload TSV file:',
      resetButton: 'Reset',
      demandFile: {
        title: 'Demand Data'
      },
      productMasterFile: {
        title: 'Product Master'
      },
      machineResourcesFile: {
        title: 'Machine Resources'
      }
    },
    machineUsageChart: {
      title: 'Resource Usage Chart',
      resourceFilter: 'Resource Filter',
      averageUtilization: 'Average Utilization Rate',
      total: 'Total',
      usage: 'Usage',
      resourceLimit: 'Resource Limit',
      quantity: 'Quantity'
    },
    resultDisplay: {
      productionPlan: 'Production Plan',
      inventoryPlan: 'Inventory Plan',
      resourceUsage: 'Resource Usage',
      csv: 'CSV',
      graph: 'Graph',
      productionGraphTitle: 'Production Plan, Inventory Plan, and Demand Data Graph',
      resourceUsageGraphTitle: 'Resource Usage Graph',
      productFilter: 'Product Filter',
      total: 'Total',
      productionQuantity: 'Production Quantity',
      inventoryQuantity: 'Inventory Quantity',
      demandQuantity: 'Demand Quantity',
      totalProductionQuantity: 'Total Production Quantity',
      totalInventoryQuantity: 'Total Inventory Quantity',
      totalDemandQuantity: 'Total Demand Quantity'
    },
    changeEmail: {
      title: 'Manufacture Optimizer Change Email Address',
      newEmailPlaceholder: 'New email address',
      passwordPlaceholder: 'Password',
      submitButton: 'Send Verification Email',
      backToHome: 'Back to Home',
      emailPasswordOnlyAlert: 'Email address can only be changed for users who signed in with email/password.',
      verificationEmailSent: 'A verification email has been sent to the new email address.',
      invalidCredentials: 'Invalid email or password.'
    },
    confirmPasswordReset: {
      title: 'Manufacture Optimizer Reset Password',
      newPasswordPlaceholder: 'New password',
      passwordRequirements: 'Password must be at least 6 characters long and include uppercase, lowercase, and numeric characters.',
      resetButton: 'Reset Password',
      invalidPasswordAlert: 'Password must be at least 6 characters long and include uppercase, lowercase, and numeric characters.',
      passwordResetSuccess: 'Your password has been reset successfully.',
      passwordResetError: 'An error occurred while resetting your password. Please try again.'
    },
    notFound: {
      message: 'The page you are looking for could not be found.',
      backToHome: 'Back to Home'
    },
    optimizeClose: {
      title: 'Manufacture Optimizer',
      trialStatus: 'Trial Period',
      plusPlan: 'Plus Plan',
      calculationCount: 'Calculation Count',
      aboutPlusPlan: 'About Plus Plan',
      loading: 'Loading...',
      contractInfo: 'Contract Information',
      changeEmail: 'Change Email Address',
      userManual: 'User Manual',
      contact: 'Contact Us',
      logout: 'Logout',
      selectConstraints: 'Select Constraints',
      constraints: 'Constraints',
      name: 'Name',
      description: 'Description',
      select: 'Select',
      plusPlanOnly: 'Plus Plan Only',
      close: 'Close',
      demandData: 'Demand Data',
      productMaster: 'Product Master',
      resourceMaster: 'Resource Master',
      calculating: 'Calculating...',
      optimize: 'Optimize',
      calculatingMessage: 'Calculation in progress. Please wait.',
      selectTsvFile: 'Please select a TSV file.',
      logoutFailed: 'Logout failed:',
      redirectingToBillingPortal: 'Redirecting to billing portal...',
      errorRedirectingToBillingPortal: 'Error redirecting to billing portal:',
      errorTryAgain: 'An error occurred. Please try again.',
      customerInfoNotFound: 'Customer information not found.',
      error: 'Error:',
      errorOccurred: 'An error occurred.',
      minimumTwoLines: 'The TSV file must have at least 2 lines (header and at least one data row).',
      minimumTwoColumns: 'The header row of the TSV file must have at least 2 columns.',
      columnMismatch: 'The number of columns in line {lineNumber} does not match the header.',
      premiumOnlyAlert: 'This algorithm is for Premium plan users only. Please upgrade to the Plus plan to use this algorithm.',
    },
    optimizeOpen: {
      demandData: 'Demand Data',
      productMaster: 'Product Master',
      resourceMaster: 'Resource Master',
      calculating: 'Calculating...',
      optimize: 'Optimize',
      calculatingMessage: 'Calculation in progress. Please wait.',
      errorMaxRows: 'The TSV file should have no more than {maxRows} rows.',
      errorNotTsv: 'Please select a TSV file.',
      errorMaxColumns: 'The TSV file should have no more than {maxCols} columns.',
      error: 'Error:',
      errorOccurred: 'An error occurred. Please check the console for details.',
      defaultDemandData: 'Product/Date\tJune 1\tJune 2\tJune 3\nP1\t7\t8\t10\nP2\t4\t14\t3\nP3\t8\t9\t24',
      defaultProductMasterData: 'Product\tInitial Inventory\tMachine\nP1\t3\tB\nP2\t5\tB\nP3\t10\tA',
      defaultMachineResourcesData: 'Resource Name/Date\tJune 1\tJune 2\tJune 3\nA\t10\t10\t10\nB\t15\t15\t15'
    },
    requestPasswordReset: {
      title: 'Forgot Your Password',
      instruction: 'Enter your registered email address. We will send you a link to reset your password.',
      emailPlaceholder: 'Email address',
      submitButton: 'Send Password Reset Link',
      backToLogin: 'Back to Login',
      successMessage: 'A password reset link has been sent to your email. Please check your inbox.',
      errorLogging: 'Error sending password reset email:',
      errorMessage: 'An error occurred while sending the password reset email. Please try again.'
    },
    userLogin: {
      title: 'Manufacture Optimizer Login',
      emailPlaceholder: 'Email address',
      passwordPlaceholder: 'Password',
      termsAgreement: 'By logging in, you agree to our <a href="https://manufactureoptimizer.notion.site/92bf766fa6954ea39b697f4d4d0c62b9" target="_blank" rel="noopener noreferrer">Terms of Service</a>.<br>For details, please read our <a href="https://manufactureoptimizer.notion.site/0fa40ed7e99a477aba267615ded6cdf4" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.',
      loginButton: 'Login',
      or: 'or',
      googleLogin: 'Login with Google Account',
      noAccount: "Don't have an account yet?",
      register: 'Create an account',
      forgotPassword: 'Forgot your password?',
      backToTop: 'Back to Top Page',
      verifyEmailAlert: 'Please verify your email before logging in. A verification email has been sent.',
      invalidCredentials: 'Invalid email or password.',
      emailChangeSuccess: 'Your email address has been successfully changed.'
    },
    userRegistration: {
      title: 'Welcome to Manufacture Optimizer',
      emailPlaceholder: 'Email address',
      passwordPlaceholder: 'Password',
      confirmPasswordPlaceholder: 'Confirm password',
      termsAgreement: 'By registering, you agree to our <a href="https://manufactureoptimizer.notion.site/92bf766fa6954ea39b697f4d4d0c62b9" target="_blank" rel="noopener noreferrer">Terms of Service</a>.<br>For details, please read our <a href="https://manufactureoptimizer.notion.site/0fa40ed7e99a477aba267615ded6cdf4" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.',
      registerButton: 'Create Account (Free)',
      or: 'or',
      googleRegister: 'Register with Google Account',
      haveAccount: 'Already have an account?',
      loginLink: 'Login here',
      invalidPassword: 'Password must be at least 6 characters long and include uppercase, lowercase, numbers, and symbols.',
      passwordMismatch: 'Passwords do not match',
      verificationEmailSent: 'A verification email has been sent. Please click the link in the email to complete your registration.',
      invalidEmail: 'Invalid email format.',
      emailInUse: 'This email is already in use.',
      weakPassword: 'Password is too weak. Please choose a stronger password.',
      registrationFailed: 'Registration failed.',
      googleRegistrationFailed: 'Google registration failed.'
    },
    routerTitles: {
      serviceTop: 'Top | Manufacture Optimizer',
      login: 'Login | Manufacture Optimizer',
      changeEmail: 'Change Email | Manufacture Optimizer',
      register: 'Create Account | Manufacture Optimizer',
      forgotPassword: 'Reset Password | Manufacture Optimizer',
      confirmPasswordReset: 'Confirm Password Reset | Manufacture Optimizer',
      notFound: 'Page Not Found | Manufacture Optimizer',
      optimizeClose: 'Manufacture Optimizer',
      addAlgorithm: 'Add Algorithm | Manufacture Optimizer',
      default: 'Manufacture Optimizer'
    }
  },
  ja: {
    welcome: "ようこそ",
    register: "アカウント作成(無料)",
    login: "ログイン",
    contactForm: "お問い合わせフォーム",
    heroTitle: "生産計画の効率化を実現",
    heroDescription: "効率化と生産性向上を実現する最適化ソリューション",
    tryDemo: "デモを試す",
    featuresTitle: "サービスの特徴",
    features: {
      algorithm: {
        title: "最適化アルゴリズム",
        description: "最適化アルゴリズムを使用し、生産工程を効率化します。"
      },
      realtime: {
        title: "リアルタイムデータ分析",
        description: "生産データを分析し、迅速な意思決定をサポートします。"
      },
      affordable: {
        title: "安価で高機能",
        description: "0円から始められ、要望に合わせて制約条件を追加します。"
      }
    },
    optimizationTitle: "デモンストレーションをお試しください。",
    optimizationDescription: "アカウント作成（無料）で、様々な制約条件も使用できるようになります。（無料）",
    optimizationGuide: "使用方法は<a href='https://manufactureoptimizer.notion.site/6cb9cbc8d6ea4f33b3a88a039a013f0d?pvs=74' target='_blank' style='color: lightblue;'>こちら</a>。",
    constraintsTitle: "# デモンストレーションの内容",
    constraintsDescription: "あなたは製造工場の生産計画担当者です。以下の条件を満たしつつ、生産計画を作成してください。<br><br><b>制約条件</b><ul><li><b>機械リソースを超えない</b>：各機械の稼働時間は、1日の最大稼働時間を超えてはならない。</li></ul><b>希望条件</b><br><b>優先度高</b>：<br><ul><li><b>在庫不足の最小化</b>：需要を可能な限り満たすこと。</li></ul><b>優先度中</b>：<br><ul><li><b>総生産量の最小化</b>：無駄な生産を減らし、必要最小限の生産を行うこと。</li><li><b>1品種切替の最小化</b>：生産する品種の切替を最小限にすること。</li><li><b>機械切替の最小化</b>：機械の稼働に伴う設定変更や調整を最小限にすること。</li></ul><b>優先度低</b>：<br><ul><li><b>早く（前詰めで）生産を完了させる</b>：できるだけ早く全ての生産を終えること。</li></ul><b>入力データ</b><br><ul><li><b>需要情報</b>：各品種ごとの需要量と納期。</li><li><b>品種情報</b>：品種ごとの初期在庫数量、生産可能な機械。</li><li><b>機械情報</b>：各機械の日付けごとの生産可能数量。</li></ul><b>出力データ</b><br><ul><li><b>生産計画</b>：各機械ごとの稼働スケジュール（品種ごとの生産開始時間と終了時間、品種切替時間を含む）。</li><li><b>在庫の変動</b>：生産計画に基づく在庫の増減。</li><li><b>需要に対する供給の充足度</b>：どれだけ需要を満たせたか。</li></ul>",
    loading: "読み込み中...",
    faq: {
      title: "よくある質問",
      items: [
        {
          question: "このサービスはどのような企業に適していますか？",
          answer: "製造業を中心に、生産工程の最適化を必要とするあらゆる規模の企業に適しています。特に、効率化とコスト削減を目指す企業に大きな価値を提供します。"
        },
        {
          question: "無料で利用できますか？",
          answer: "はい。無料でアカウント作成＆計算の実行をご利用できます。ただし無料の場合、計算回数は月20回までとさせていただいております。Plusプラン($7 約1,000円/月)にご加入いただくと無制限でご利用できます。"
        },
        {
          question: "制約条件は変更できますか？",
          answer: "Plusプランに申込いただくと制約条件の追加依頼が可能です。（※内容によっては難しい場合がございます。まずはお問い合わせフォームからご相談ください。相談は無料です。）"
        },
        {
          question: "お支払い方法を教えてください。",
          answer: "お支払いはクレジットカード払いのみ対応しております。"
        },
        {
          question: "個人で利用可能ですか？",
          answer: "法人・個人どちらでも利用可能です。"
        }
      ]
    },
    footer: {
      termsOfService: "利用規約",
      privacyPolicy: "プライバシーポリシー",
      commercialTransactions: "特定商取引法に基づく表記",
      contactForm: "お問い合わせフォーム",
      login: "ログイン"
    },
    pageTitle: "Manufacture Optimizer | シンプル生産スケジューラ",
    metaDescription: "効率化と生産性向上を実現するシンプル生産スケジューラ。AI技術を活用し、製造業の課題解決をサポートします。",
    metaKeywords: "生産工程最適化, 製造業AI, 生産性向上, 生産スケジューリング, 無料生産管理ソフト, 低コスト生産計画, 工場効率化, サプライチェーン最適化, 製造業DX, 中小企業向け生産管理, IoT生産管理, リアルタイム生産分析, 在庫最適化, 納期遵守率向上, 製造業コスト削減, フリーソフト",
    schemaName: "Manufacture Optimizer シンプル生産スケジューラ",
    schemaDescription: "効率化と生産性向上を実現するシンプル生産スケジューラ。AI技術を活用し、製造業の課題解決をサポートします。",
    chart: {
      title: '生産計画・在庫計画・需要データ',
      yAxis: {
        name: '数量'
      },
      legend: {
        '合計生産数量': '生産数量',
        '合計在庫数量': '在庫数量',
        '合計需要数量': '需要数量'
      }
    },
    dataInput: {
      uploadLabel: 'TSVファイルをアップロード:',
      resetButton: 'リセット',
      demandFile: {
        title: '需要データ'
      },
      productMasterFile: {
        title: '品種マスタ'
      },
      machineResourcesFile: {
        title: 'リソースマスタ'
      }
    },
    machineUsageChart: {
      title: 'リソース使用状況グラフ',
      resourceFilter: 'リソースフィルター',
      averageUtilization: '平均稼働率',
      total: '合計',
      usage: '使用量',
      resourceLimit: 'リソース上限',
      quantity: '数量'
    },
    resultDisplay: {
      productionPlan: '生産計画',
      inventoryPlan: '在庫計画',
      resourceUsage: 'リソース使用状況',
      csv: 'CSV',
      graph: 'グラフ',
      productionGraphTitle: '生産計画・在庫計画・需要データ グラフ',
      resourceUsageGraphTitle: 'リソース使用状況グラフ',
      productFilter: '製品フィルター',
      total: '合計',
      productionQuantity: '生産数量',
      inventoryQuantity: '在庫数量',
      demandQuantity: '需要数量',
      totalProductionQuantity: '合計生産数量',
      totalInventoryQuantity: '合計在庫数量',
      totalDemandQuantity: '合計需要数量'
    },
    changeEmail: {
      title: 'Manufacture Optimizer メールアドレスの変更',
      newEmailPlaceholder: '新しいメールアドレス',
      passwordPlaceholder: 'パスワード',
      submitButton: '認証メールを送信する',
      backToHome: 'ホームへ戻る',
      emailPasswordOnlyAlert: 'メールアドレスは、メール/パスワードでサインインしているユーザーのみ変更可能です。',
      verificationEmailSent: '新しいメールアドレスに確認メールが送信されました。',
      invalidCredentials: 'メールアドレスまたはパスワードが間違っています。'
    },
    confirmPasswordReset: {
      title: 'Manufacture Optimizer パスワードのリセット',
      newPasswordPlaceholder: '新しいパスワード',
      passwordRequirements: 'パスワードは6文字以上で、英大文字、英小文字、数字を含める必要があります。',
      resetButton: 'パスワードをリセット',
      invalidPasswordAlert: 'パスワードは6文字以上で、英大文字、英小文字、数字を含める必要があります。',
      passwordResetSuccess: 'パスワードが正常にリセットされました。',
      passwordResetError: 'パスワードのリセット中にエラーが発生しました。もう一度お試しください。'
    },
    notFound: {
      message: 'お探しのページは見つかりませんでした。',
      backToHome: 'ホームに戻る'
    },
    optimizeClose: {
      title: 'Manufacture Optimizer',
      trialStatus: 'トライアル中',
      plusPlan: 'Plusプラン',
      calculationCount: '計算回数',
      aboutPlusPlan: 'Plusプランについて',
      loading: '読み込み中...',
      contractInfo: '契約情報',
      changeEmail: 'メールアドレス変更',
      userManual: 'ご利用マニュアル',
      contact: 'お問い合わせ',
      logout: 'ログアウト',
      selectConstraints: '制約条件を選択',
      constraints: '制約条件',
      name: '名前',
      description: '説明',
      select: '選択',
      plusPlanOnly: 'Plusプラン限定',
      close: '閉じる',
      demandData: '需要データ',
      productMaster: '品種マスタ',
      resourceMaster: 'リソースマスタ',
      calculating: '計算中...',
      optimize: '最適化',
      calculatingMessage: '計算中です。しばらくお待ちください。',
      selectTsvFile: 'TSVファイルを選択してください。',
      logoutFailed: 'ログアウトに失敗しました：',
      redirectingToBillingPortal: '請求ポータルにリダイレクトしています...',
      errorRedirectingToBillingPortal: '請求ポータルへのリダイレクト中にエラーが発生しました：',
      errorTryAgain: 'エラーが発生しました。もう一度お試しください。',
      customerInfoNotFound: '顧客情報が見つかりません。',
      error: 'エラー：',
      errorOccurred: 'エラーが発生しました。',
      minimumTwoLines: 'TSVファイルには少なくとも2行（ヘッダーと1行以上のデータ）が必要です。',
      minimumTwoColumns: 'TSVファイルのヘッダー行には少なくとも2列が必要です。',
      columnMismatch: '行 {lineNumber} の列数がヘッダーと一致しません。',
      premiumOnlyAlert: 'このアルゴリズムはPremiumプラン専用です。このアルゴリズムを使用するにはPlusプランにアップグレードしてください。',
    },
    optimizeOpen: {
      demandData: '需要データ',
      productMaster: '品種マスタ',
      resourceMaster: 'リソースマスタ',
      calculating: '計算中...',
      optimize: '最適化',
      calculatingMessage: '計算中です。しばらくお待ちください。',
      errorMaxRows: 'TSVファイルの行数は{maxRows}以下にしてください。',
      errorNotTsv: 'TSVファイルを選択してください。',
      errorMaxColumns: 'TSVファイルの列数は{maxCols}以下にしてください。',
      error: 'エラー：',
      errorOccurred: 'エラーが発生しました。詳細はコンソールを確認してください。',
      defaultDemandData: '品種/日付\t6/1\t6/2\t6/3\nP1\t7\t8\t10\nP2\t4\t14\t3\nP3\t8\t9\t24',
      defaultProductMasterData: '品種/品種情報\tInitial Inventory\tMachine\nP1\t3\tB\nP2\t5\tB\nP3\t10\tA',
      defaultMachineResourcesData: 'リソース名/日付\t6/1\t6/2\t6/3\nA\t10\t10\t10\nB\t15\t15\t15'
    },
    requestPasswordReset: {
      title: 'パスワードをお忘れの方',
      instruction: '登録済みのメールアドレスを入力してください。パスワード再設定用のリンクをお送りします。',
      emailPlaceholder: 'メールアドレス',
      submitButton: 'パスワード再設定用のリンクを送信',
      backToLogin: 'ログイン画面に戻る',
      successMessage: 'パスワード再設定用のリンクを送信しました。メールをご確認ください。',
      errorLogging: 'パスワード再設定用メールの送信中にエラーが発生しました：',
      errorMessage: 'パスワード再設定用メールの送信中にエラーが発生しました。もう一度お試しください。'
    },
    userLogin: {
      title: 'Manufacture Optimizer ログイン',
      emailPlaceholder: 'メールアドレス',
      passwordPlaceholder: 'パスワード',
      termsAgreement: 'ログインすることで、<a href="https://manufactureoptimizer.notion.site/92bf766fa6954ea39b697f4d4d0c62b9" target="_blank" rel="noopener noreferrer">利用規約</a>に同意したことになります。<br>詳しくは<a href="https://manufactureoptimizer.notion.site/0fa40ed7e99a477aba267615ded6cdf4" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>をお読みください。',
      loginButton: 'ログイン',
      or: 'または',
      googleLogin: 'Googleアカウントでログイン',
      noAccount: 'まだアカウントをお持ちでない方は',
      register: 'こちら',
      forgotPassword: 'パスワードをお忘れの方',
      backToTop: 'トップページ',
      verifyEmailAlert: 'ログインする前にメール認証を行ってください。認証メールを送信しました。',
      invalidCredentials: 'メールアドレスまたはパスワードが間違っています。',
      emailChangeSuccess: 'メールアドレスの変更が正常に完了しました。'
    },
    userRegistration: {
      title: 'Manufacture Optimizerへようこそ',
      emailPlaceholder: 'メールアドレス',
      passwordPlaceholder: 'パスワード',
      confirmPasswordPlaceholder: 'パスワード（確認）',
      termsAgreement: '登録することで、<a href="https://manufactureoptimizer.notion.site/92bf766fa6954ea39b697f4d4d0c62b9" target="_blank" rel="noopener noreferrer">利用規約</a>に同意したことになります。<br>詳しくは<a href="https://manufactureoptimizer.notion.site/0fa40ed7e99a477aba267615ded6cdf4" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>をお読みください。',
      registerButton: 'アカウント作成（無料）',
      or: 'または',
      googleRegister: 'Googleアカウントで登録',
      haveAccount: 'アカウントをお持ちの方は',
      loginLink: 'こちらからログイン',
      invalidPassword: 'パスワードは6文字以上で、英大文字、英小文字、数字、および記号を含める必要があります。',
      passwordMismatch: 'パスワードが一致しません',
      verificationEmailSent: '確認メールを送信しました。メール内のリンクをクリックして登録を完了してください。',
      invalidEmail: 'メールアドレスの形式が正しくありません。',
      emailInUse: 'このメールアドレスはすでに使用されています。',
      weakPassword: 'パスワードが脆弱です。もっと強固なパスワードを設定してください。',
      registrationFailed: '登録に失敗しました。',
      googleRegistrationFailed: 'Googleでの登録に失敗しました。'
    },
    routerTitles: {
      serviceTop: 'Manufacture Optimizer',
      login: 'ログイン | Manufacture Optimizer',
      changeEmail: 'メールアドレス変更 | Manufacture Optimizer',
      register: 'アカウント作成 | Manufacture Optimizer',
      forgotPassword: 'パスワードリセット | Manufacture Optimizer',
      confirmPasswordReset: 'パスワードリセット確認 | Manufacture Optimizer',
      notFound: 'ページが見つかりません | Manufacture Optimizer',
      optimizeClose: 'Manufacture Optimizer',
      addAlgorithm: 'アルゴリズム追加 | Manufacture Optimizer',
      default: 'Manufacture Optimizer'
    }
  },
  de: {
    welcome: "Willkommen",
    register: "Konto erstellen (Kostenlos)",
    login: "Anmelden",
    contactForm: "Kontaktformular",
    heroTitle: "Erreichen Sie eine effizientere Produktionsplanung",
    heroDescription: "Optimierungslösung für Effizienz- und Produktivitätssteigerung",
    tryDemo: "Demo ausprobieren",
    featuresTitle: "Leistungsmerkmale",
    features: {
      algorithm: {
        title: "Optimierungsalgorithmus",
        description: "Optimieren Sie Produktionsprozesse mithilfe von Optimierungsalgorithmen."
      },
      realtime: {
        title: "Echtzeit-Datenanalyse",
        description: "Analysieren Sie Produktionsdaten zur Unterstützung schneller Entscheidungsfindung."
      },
      affordable: {
        title: "Kostengünstig und funktionsreich",
        description: "Beginnen Sie ab 0€, mit Randbedingungen, die nach Ihren Bedürfnissen hinzugefügt werden."
      }
    },
    optimizationTitle: "Probieren Sie die Demonstration aus",
    optimizationDescription: "Durch die Erstellung eines Kontos können Sie verschiedene Einschränkungen nutzen (kostenlos).",
    optimizationGuide: "Für Nutzungsanweisungen klicken Sie <a href='https://manufactureoptimizer.notion.site/Gebrauchsanweisung-618b88df6c2c4a049bcce186a1e1bec3?pvs=74' target='_blank' style='color: lightblue;'>hier</a>.",
    constraintsTitle: "# Demonstrationsinhalt",
    constraintsDescription: "Sie sind Produktionsplaner in einer Fertigungsanlage. Bitte erstellen Sie einen Produktionsplan, der die folgenden Bedingungen erfüllt.<br><br><b>Einschränkungen</b><ul><li><b>Maschinenressourcen nicht überschreiten</b>: Die Betriebszeit jeder Maschine darf die maximale Betriebszeit pro Tag nicht überschreiten.</li></ul><b>Hohe Priorität</b>:<br><ul><li><b>Minimierung von Lagerengpässen</b>: Erfüllen Sie die Nachfrage so weit wie möglich.</li></ul><b>Mittlere Priorität</b>:<br><ul><li><b>Minimierung der Gesamtproduktion</b>: Reduzieren Sie unnötige Produktion und produzieren Sie nur das erforderliche Minimum.</li><li><b>Minimierung der Produktswechsel</b>: Minimieren Sie das Umschalten zwischen verschiedenen zu produzierenden Produkten.</li><li><b>Minimierung der Maschinenwechsel</b>: Minimieren Sie die für den Betrieb der Maschinen erforderlichen Einstellungen und Anpassungen.</li></ul><b>Niedrige Priorität</b>:<br><ul><li><b>Fertigstellung der Produktion frühzeitig</b>: Schließen Sie die gesamte Produktion so schnell wie möglich ab.</li></ul><b>Eingabedaten</b><br><ul><li><b>Nachfrageinformationen</b>: Die Nachfragemenge und das Lieferdatum für jeden Produkttyp.</li><li><b>Produktinformationen</b>: Anfangsbestandsmenge für jeden Produkttyp, Maschinen, die sie produzieren können.</li><li><b>Maschineninformationen</b>: Die produzierbare Menge jeder Maschine für jedes Datum.</li></ul><b>Ausgabedaten</b><br><ul><li><b>Produktionsplan</b>: Der Betriebsplan für jede Maschine (einschließlich Start- und Endzeiten für die Produktion jedes Produkttyps und Umschaltzeiten zwischen den Produkttypen).</li><li><b>Bestandsänderungen</b>: Bestandszunahmen und -abnahmen basierend auf dem Produktionsplan.</li><li><b>Erfüllung der Nachfrage</b>: Wie viel der Nachfrage erfüllt wurde.</li></ul>",
    loading: "Laden...",
    faq: {
      title: "Häufig gestellte Fragen",
      items: [
        {
          question: "Für welche Art von Unternehmen ist dieser Dienst geeignet?",
          answer: "Er ist für Unternehmen aller Größen geeignet, die ihre Produktionsprozesse optimieren müssen, insbesondere in der Fertigung. Er bietet großen Wert für Unternehmen, die Effizienz und Kostensenkung anstreben."
        },
        {
          question: "Kann ich es kostenlos nutzen?",
          answer: "Ja, Sie können ein Konto erstellen und Berechnungen kostenlos durchführen. Die kostenlose Nutzung ist jedoch auf 20 Berechnungen pro Monat begrenzt. Mit einem Plus-Plan-Abonnement ($7 pro Monat) können Sie es unbegrenzt nutzen."
        },
        {
          question: "Kann ich die Randbedingungen ändern?",
          answer: "Wenn Sie den Plus-Plan abonnieren, können Sie zusätzliche Randbedingungen anfordern. (Hinweis: Je nach Inhalt können einige Anfragen schwierig umzusetzen sein. Bitte kontaktieren Sie uns zunächst über das Anfrageformular. Die Beratung ist kostenlos.)"
        },
        {
          question: "Welche Zahlungsmethoden gibt es?",
          answer: "Wir akzeptieren nur Kreditkartenzahlungen."
        },
        {
          question: "Können Einzelpersonen es nutzen?",
          answer: "Sowohl Unternehmen als auch Einzelpersonen können unseren Dienst nutzen."
        }
      ]
    },
    footer: {
      termsOfService: "Nutzungsbedingungen",
      privacyPolicy: "Datenschutzrichtlinie",
      commercialTransactions: "Handelsrecht",
      contactForm: "Kontaktformular",
      login: "Anmelden"
    },
    pageTitle: "Einfacher Produktionsplaner | Manufacture Optimizer",
    metaDescription: "Ein einfacher Produktionsplaner, der Effizienz und Produktivitätssteigerung realisiert. Wir unterstützen die Problemlösung in der Fertigung mit KI-Technologie.",
    metaKeywords: "Produktionsprozessoptimierung, Fertigungs-KI, Produktivitätssteigerung, Produktionsplanung, kostenlose Produktionsmanagement-Software, kostengünstige Produktionsplanung, Fabrikeffizienz, Lieferkettenoptimierung, Fertigungs-DX, Produktionsmanagement für KMU, IoT-Produktionsmanagement, Echtzeit-Produktionsanalyse, Bestandsoptimierung, Verbesserung der Liefertermin-Einhaltungsrate, Fertigungskostenreduzierung, kostenlose Software",
    schemaName: "Manufacture Optimizer Einfacher Produktionsplaner",
    schemaDescription: "Ein einfacher Produktionsplaner, der Effizienz und Produktivitätssteigerung realisiert. Wir unterstützen die Problemlösung in der Fertigung mit KI-Technologie.",
    chart: {
      title: 'Produktionsplan, Lagerbestand und Nachfragedaten',
      yAxis: {
        name: 'Menge'
      },
      legend: {
        'Gesamtproduktionsmenge': 'Produktionsmenge',
        'Gesamtlagerbestand': 'Lagerbestand',
        'Gesamtnachfragemenge': 'Nachfragemenge'
      }
    },
    dataInput: {
      uploadLabel: 'TSV-Datei hochladen:',
      resetButton: 'Zurücksetzen',
      demandFile: {
        title: 'Nachfragedaten'
      },
      productMasterFile: {
        title: 'Produktstammdaten'
      },
      machineResourcesFile: {
        title: 'Maschinenressourcen'
      }
    },
    machineUsageChart: {
      title: 'Ressourcennutzungsdiagramm',
      resourceFilter: 'Ressourcenfilter',
      averageUtilization: 'Durchschnittliche Auslastung',
      total: 'Gesamt',
      usage: 'Nutzung',
      resourceLimit: 'Ressourcenlimit',
      quantity: 'Menge'
    },
    resultDisplay: {
      productionPlan: 'Produktionsplan',
      inventoryPlan: 'Lagerbestandsplan',
      resourceUsage: 'Ressourcennutzung',
      csv: 'CSV',
      graph: 'Diagramm',
      productionGraphTitle: 'Produktionsplan, Lagerbestandsplan und Nachfragedaten-Diagramm',
      resourceUsageGraphTitle: 'Ressourcennutzungsdiagramm',
      productFilter: 'Produktfilter',
      total: 'Gesamt',
      productionQuantity: 'Produktionsmenge',
      inventoryQuantity: 'Lagerbestandsmenge',
      demandQuantity: 'Nachfragemenge',
      totalProductionQuantity: 'Gesamtproduktionsmenge',
      totalInventoryQuantity: 'Gesamtlagerbestandsmenge',
      totalDemandQuantity: 'Gesamtnachfragemenge'
    },
    changeEmail: {
      title: 'Manufacture Optimizer E-Mail-Adresse ändern',
      newEmailPlaceholder: 'Neue E-Mail-Adresse',
      passwordPlaceholder: 'Passwort',
      submitButton: 'Bestätigungs-E-Mail senden',
      backToHome: 'Zurück zur Startseite',
      emailPasswordOnlyAlert: 'Die E-Mail-Adresse kann nur für Benutzer geändert werden, die sich mit E-Mail/Passwort angemeldet haben.',
      verificationEmailSent: 'Eine Bestätigungs-E-Mail wurde an die neue E-Mail-Adresse gesendet.',
      invalidCredentials: 'Ungültige E-Mail-Adresse oder ungültiges Passwort.'
    },
    confirmPasswordReset: {
      title: 'Manufacture Optimizer Passwort zurücksetzen',
      newPasswordPlaceholder: 'Neues Passwort',
      passwordRequirements: 'Das Passwort muss mindestens 6 Zeichen lang sein und Großbuchstaben, Kleinbuchstaben und Zahlen enthalten.',
      resetButton: 'Passwort zurücksetzen',
      invalidPasswordAlert: 'Das Passwort muss mindestens 6 Zeichen lang sein und Großbuchstaben, Kleinbuchstaben und Zahlen enthalten.',
      passwordResetSuccess: 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
      passwordResetError: 'Beim Zurücksetzen des Passworts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
    },
    notFound: {
      message: 'Die gesuchte Seite wurde nicht gefunden.',
      backToHome: 'Zurück zur Startseite'
    },
    optimizeClose: {
      title: 'Manufacture Optimizer',
      trialStatus: 'Testphase',
      plusPlan: 'Plus-Plan',
      calculationCount: 'Berechnungsanzahl',
      aboutPlusPlan: 'Über den Plus-Plan',
      loading: 'Laden...',
      contractInfo: 'Vertragsinformationen',
      changeEmail: 'E-Mail-Adresse ändern',
      userManual: 'Benutzerhandbuch',
      contact: 'Kontakt',
      logout: 'Abmelden',
      selectConstraints: 'Randbedingungen auswählen',
      constraints: 'Randbedingungen',
      name: 'Name',
      description: 'Beschreibung',
      select: 'Auswählen',
      plusPlanOnly: 'Nur Plus-Plan',
      close: 'Schließen',
      demandData: 'Nachfragedaten',
      productMaster: 'Produktstammdaten',
      resourceMaster: 'Ressourcenstammdaten',
      calculating: 'Berechnung läuft...',
      optimize: 'Optimieren',
      calculatingMessage: 'Berechnung läuft. Bitte warten.',
      selectTsvFile: 'Bitte wählen Sie eine TSV-Datei aus.',
      logoutFailed: 'Abmeldung fehlgeschlagen:',
      redirectingToBillingPortal: 'Weiterleitung zum Abrechnungsportal...',
      errorRedirectingToBillingPortal: 'Fehler bei der Weiterleitung zum Abrechnungsportal:',
      errorTryAgain: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
      customerInfoNotFound: 'Kundeninformationen nicht gefunden.',
      error: 'Fehler:',
      errorOccurred: 'Ein Fehler ist aufgetreten.',
      minimumTwoLines: 'Die TSV-Datei muss mindestens 2 Zeilen haben (Kopfzeile und mindestens eine Datenzeile).',
      minimumTwoColumns: 'Die Kopfzeile der TSV-Datei muss mindestens 2 Spalten haben.',
      columnMismatch: 'Die Anzahl der Spalten in Zeile {lineNumber} stimmt nicht mit der Kopfzeile überein.',
      premiumOnlyAlert: 'Dieser Algorithmus ist nur für Premium-Plan-Benutzer verfügbar. Bitte upgraden Sie auf den Plus-Plan, um diesen Algorithmus zu verwenden.',
    },
    optimizeOpen: {
      demandData: 'Nachfragedaten',
      productMaster: 'Produktstammdaten',
      resourceMaster: 'Ressourcenstammdaten',
      calculating: 'Berechnung läuft...',
      optimize: 'Optimieren',
      calculatingMessage: 'Berechnung läuft. Bitte warten.',
      errorMaxRows: 'Die TSV-Datei sollte nicht mehr als {maxRows} Zeilen haben.',
      errorNotTsv: 'Bitte wählen Sie eine TSV-Datei aus.',
      errorMaxColumns: 'Die TSV-Datei sollte nicht mehr als {maxCols} Spalten haben.',
      error: 'Fehler:',
      errorOccurred: 'Ein Fehler ist aufgetreten. Bitte überprüfen Sie die Konsole für Details.',
      defaultDemandData: 'Produkt/Datum\t1. Juni\t2. Juni\t3. Juni\nP1\t7\t8\t10\nP2\t4\t14\t3\nP3\t8\t9\t24',
      defaultProductMasterData: 'Produkt\tAnfangsbestand\tMaschine\nP1\t3\tB\nP2\t5\tB\nP3\t10\tA',
      defaultMachineResourcesData: 'Ressourcenname/Datum\t1. Juni\t2. Juni\t3. Juni\nA\t10\t10\t10\nB\t15\t15\t15'
    },
    requestPasswordReset: {
      successMessage: 'Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang.',
      errorLogging: 'Fehler beim Senden der E-Mail zum Zurücksetzen des Passworts:',
      errorMessage: 'Beim Senden der E-Mail zum Zurücksetzen des Passworts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
    },
    userLogin: {
      title: 'Manufacture Optimizer Anmeldung',
      emailPlaceholder: 'E-Mail-Adresse',
      passwordPlaceholder: 'Passwort',
      termsAgreement: 'Durch die Anmeldung stimmen Sie unseren <a href="https://manufactureoptimizer.notion.site/92bf766fa6954ea39b697f4d4d0c62b9" target="_blank" rel="noopener noreferrer">Nutzungsbedingungen</a> zu.<br>Für Details lesen Sie bitte unsere <a href="https://manufactureoptimizer.notion.site/0fa40ed7e99a477aba267615ded6cdf4" target="_blank" rel="noopener noreferrer">Datenschutzrichtlinie</a>.',
      loginButton: 'Anmelden',
      or: 'oder',
      googleLogin: 'Mit Google-Konto anmelden',
      noAccount: 'Noch kein Konto?',
      register: 'Konto erstellen',
      forgotPassword: 'Passwort vergessen?',
      backToTop: 'Zurück zur Startseite',
      verifyEmailAlert: 'Bitte bestätigen Sie Ihre E-Mail-Adresse vor der Anmeldung. Eine Bestätigungs-E-Mail wurde gesendet.',
      invalidCredentials: 'Ungültige E-Mail-Adresse oder ungültiges Passwort.',
      emailChangeSuccess: 'Ihre E-Mail-Adresse wurde erfolgreich geändert.'
    },
    userRegistration: {
      title: 'Willkommen bei Manufacture Optimizer',
      emailPlaceholder: 'E-Mail-Adresse',
      passwordPlaceholder: 'Passwort',
      confirmPasswordPlaceholder: 'Passwort bestätigen',
      termsAgreement: 'Durch die Registrierung stimmen Sie unseren <a href="https://manufactureoptimizer.notion.site/92bf766fa6954ea39b697f4d4d0c62b9" target="_blank" rel="noopener noreferrer">Nutzungsbedingungen</a> zu.<br>Für Details lesen Sie bitte unsere <a href="https://manufactureoptimizer.notion.site/0fa40ed7e99a477aba267615ded6cdf4" target="_blank" rel="noopener noreferrer">Datenschutzrichtlinie</a>.',
      registerButton: 'Konto erstellen (Kostenlos)',
      or: 'oder',
      googleRegister: 'Mit Google-Konto registrieren',
      haveAccount: 'Bereits ein Konto?',
      loginLink: 'Hier anmelden',
      invalidPassword: 'Das Passwort muss mindestens 6 Zeichen lang sein und Großbuchstaben, Kleinbuchstaben, Zahlen und Sonderzeichen enthalten.',
      passwordMismatch: 'Die Passwörter stimmen nicht überein',
      verificationEmailSent: 'Eine Bestätigungs-E-Mail wurde gesendet. Bitte klicken Sie auf den Link in der E-Mail, um Ihre Registrierung abzuschließen.',
      invalidEmail: 'Ungültiges E-Mail-Format.',
      emailInUse: 'Diese E-Mail-Adresse wird bereits verwendet.',
      weakPassword: 'Das Passwort ist zu schwach. Bitte wählen Sie ein stärkeres Passwort.',
      registrationFailed: 'Registrierung fehlgeschlagen.',
      googleRegistrationFailed: 'Google-Registrierung fehlgeschlagen.'
    },
    routerTitles: {
      serviceTop: 'Startseite | Manufacture Optimizer',
      login: 'Anmelden | Manufacture Optimizer',
      changeEmail: 'E-Mail ändern | Manufacture Optimizer',
      register: 'Konto erstellen | Manufacture Optimizer',
      forgotPassword: 'Passwort zurücksetzen | Manufacture Optimizer',
      confirmPasswordReset: 'Passwort-Zurücksetzung bestätigen | Manufacture Optimizer',
      notFound: 'Seite nicht gefunden | Manufacture Optimizer',
      optimizeClose: 'Manufacture Optimizer',
      addAlgorithm: 'Algorithmus hinzufügen | Manufacture Optimizer',
      default: 'Manufacture Optimizer'
    }
  }





};

const i18n = createI18n({
  legacy: false,  // この行を追加
  locale: defaultLocale, // デフォルトの言語を設定
  fallbackLocale: 'en', // フォールバック言語を設定
  messages,
});

export default i18n;