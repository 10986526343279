<template>
  <div ref="chartContainer" style="width: 100%; height: 100%;"></div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import * as echarts from 'echarts';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  chartData: Object
});

const { t, locale } = useI18n();

const chartContainer = ref(null);
let chart = null;

const initChart = () => {
  if (chartContainer.value) {
    chart = echarts.init(chartContainer.value);
  }
};

const updateChart = () => {
  if (!chart || !props.chartData) return;

  const option = {
    title: {
      text: t('chart.title')
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    legend: {
      data: props.chartData.series.map(s => t(`chart.legend.${s.name}`))
    },
    xAxis: [
      {
        type: 'category',
        data: props.chartData.dates,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: t('chart.yAxis.name'),
        min: 0,
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: props.chartData.series.map(s => ({
      name: t(`chart.legend.${s.name}`),
      type: s.type,
      data: s.data,
      itemStyle: {
        opacity: s.type === 'bar' ? 0.7 : 1
      }
    }))
  };

  chart.setOption(option);
};

onMounted(() => {
  initChart();
  updateChart();
});

watch(() => props.chartData, () => {
  updateChart();
}, { deep: true });

// 言語が変更されたときにチャートを更新
watch(() => locale.value, () => {
  updateChart();
});
</script>